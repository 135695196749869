import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog, Flex, Text } from "@radix-ui/themes";
import { useCallback, useState } from "react";
import { Region } from "services/api/dbmanagement/region_pb.js";
import { createProject, OrganizationID } from "services/user";
import { useUserClient } from "services/user/useUserClient";
import { useHasSchemas, useIsAdmin } from "state/user/hooks";
import { useRegionSelect } from "ui/admin/useRegionSelect";
import CheckBox from "ui/shared/CheckBox";
import DialogButtons from "ui/shared/DialogButtons";
import TextInput from "ui/shared/TextInput";
import { useSubmitFormHandler } from "ui/utils/forms";

type OnCreateStore = (
  name: string,
  region: Region,
  description: string,
  createSchema: boolean,
) => Promise<void>;

export function CreateStoreButton({ orgId }: { orgId: OrganizationID }) {
  const [showDialog, setShowDialog] = useState(false);
  const close = useCallback(() => setShowDialog(false), []);
  const userClient = useUserClient();

  const handleSubmit = useCallback<OnCreateStore>(
    async (name, region, description, createSchema) => {
      // We're using createProject instead of createStore, because createProject
      // can also create a bound schema and a project for the store to live in.
      await createProject(userClient, region, orgId, name, description, createSchema);

      // TODO: we should probably return the whole new project, schema, and
      // store, but instead we'll reload the page.
      window.location.reload();
    },
    [userClient, orgId],
  );

  return (
    <>
      <Dialog.Root open={showDialog} onOpenChange={setShowDialog}>
        <Dialog.Trigger>
          <Button variant="soft">
            <FontAwesomeIcon icon={faDatabase} aria-hidden="true" />
            <Text>Create Store</Text>
          </Button>
        </Dialog.Trigger>
        <Dialog.Content>
          <CreateStoreDialog onSubmit={handleSubmit} close={close} />
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}

// This gets conditionally rendered by Dialog.Content so its state resets once closed.
function CreateStoreDialog({ onSubmit, close }: { onSubmit: OnCreateStore; close: () => void }) {
  const isAdmin = useIsAdmin();
  const hasSchemas = useHasSchemas();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [region, selectRegion] = useRegionSelect();

  // If there are no schemas, we'll default to creating a schema.
  const [createSchema, setCreateSchema] = useState(!hasSchemas);

  const [loading, handleSubmit, error] = useSubmitFormHandler(async () => {
    onSubmit(name, region, description, createSchema);
  }, close);

  const canSubmit = name.length > 0 && name.length <= 64 && !loading;

  return (
    <>
      <Dialog.Title>Create Store</Dialog.Title>
      <form onSubmit={handleSubmit}>
        <Flex direction="column" gap="2">
          <TextInput label="Name" required value={name} onChange={setName} autoFocus />
          <TextInput label="Description" value={description} onChange={setDescription} />
          {selectRegion}
          {(isAdmin || hasSchemas) && (
            <CheckBox
              onChange={setCreateSchema}
              label="Also create a new schema. If you do not create a schema, you will need to bind an existing schema to this new Store."
              checked={createSchema}
            />
          )}
        </Flex>

        <DialogButtons progress={loading && "Creating store..."} error={error}>
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </Dialog.Close>
          <Button type="submit" variant="solid" disabled={!canSubmit}>
            Create Store
          </Button>
        </DialogButtons>
      </form>
    </>
  );
}
