// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/delete_organization.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/delete_organization.proto.
 */
export const file_user_delete_organization =
  /*@__PURE__*/
  fileDesc(
    "Ch51c2VyL2RlbGV0ZV9vcmdhbml6YXRpb24ucHJvdG8SDHN0YXRlbHkudXNlciI0ChlEZWxldGVPcmdhbml6YXRpb25SZXF1ZXN0EhcKD29yZ2FuaXphdGlvbl9pZBgBIAEoBCIcChpEZWxldGVPcmdhbml6YXRpb25SZXNwb25zZUJ8ChBjb20uc3RhdGVseS51c2VyQhdEZWxldGVPcmdhbml6YXRpb25Qcm90b1ABogIDU1VYqgIMU3RhdGVseS5Vc2VyygIMU3RhdGVseVxVc2Vy4gIYU3RhdGVseVxVc2VyXEdQQk1ldGFkYXRh6gINU3RhdGVseTo6VXNlcmIGcHJvdG8z",
  );

/**
 * Describes the message stately.user.DeleteOrganizationRequest.
 * Use `create(DeleteOrganizationRequestSchema)` to create a new message.
 */
export const DeleteOrganizationRequestSchema =
  /*@__PURE__*/
  messageDesc(file_user_delete_organization, 0);

/**
 * Describes the message stately.user.DeleteOrganizationResponse.
 * Use `create(DeleteOrganizationResponseSchema)` to create a new message.
 */
export const DeleteOrganizationResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_delete_organization, 1);
