import { Flex } from "@radix-ui/themes";
import { useSchemasForProject, useStoresForProject } from "state/user/hooks";
import { Project } from "state/user/types";
import { SchemaTile } from "ui/schema/SchemaTile";
import { StoreTile } from "ui/store/StoreTile";

export function ProjectSection({ project }: { project: Project }) {
  const stores = useStoresForProject(project.projectId);
  const schemas = useSchemasForProject(project.projectId);
  return (
    <>
      <Flex gap="4" direction="column">
        {stores.map((store) => (
          <StoreTile key={store.storeId.toString()} store={store} />
        ))}
      </Flex>
      <Flex gap="4" direction="column">
        {schemas.map((schema) => (
          <SchemaTile key={schema.schemaId.toString()} schema={schema} />
        ))}
      </Flex>
    </>
  );
}
