// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/enroll.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/enroll.proto.
 */
export const file_user_enroll =
  /*@__PURE__*/
  fileDesc(
    "ChF1c2VyL2Vucm9sbC5wcm90bxIMc3RhdGVseS51c2VyIg8KDUVucm9sbFJlcXVlc3QiEAoORW5yb2xsUmVzcG9uc2UiYQoYRW5yb2xsTWFjaGluZVVzZXJSZXF1ZXN0EhYKDm9fYXV0aF9zdWJqZWN0GAEgASgJEhQKDGRpc3BsYXlfbmFtZRgCIAEoCRIXCg9vcmdhbml6YXRpb25faWQYAyABKAQiGwoZRW5yb2xsTWFjaGluZVVzZXJSZXNwb25zZUJwChBjb20uc3RhdGVseS51c2VyQgtFbnJvbGxQcm90b1ABogIDU1VYqgIMU3RhdGVseS5Vc2VyygIMU3RhdGVseVxVc2Vy4gIYU3RhdGVseVxVc2VyXEdQQk1ldGFkYXRh6gINU3RhdGVseTo6VXNlcmIGcHJvdG8z",
  );

/**
 * Describes the message stately.user.EnrollRequest.
 * Use `create(EnrollRequestSchema)` to create a new message.
 */
export const EnrollRequestSchema = /*@__PURE__*/ messageDesc(file_user_enroll, 0);

/**
 * Describes the message stately.user.EnrollResponse.
 * Use `create(EnrollResponseSchema)` to create a new message.
 */
export const EnrollResponseSchema = /*@__PURE__*/ messageDesc(file_user_enroll, 1);

/**
 * Describes the message stately.user.EnrollMachineUserRequest.
 * Use `create(EnrollMachineUserRequestSchema)` to create a new message.
 */
export const EnrollMachineUserRequestSchema = /*@__PURE__*/ messageDesc(file_user_enroll, 2);

/**
 * Describes the message stately.user.EnrollMachineUserResponse.
 * Use `create(EnrollMachineUserResponseSchema)` to create a new message.
 */
export const EnrollMachineUserResponseSchema = /*@__PURE__*/ messageDesc(file_user_enroll, 3);
