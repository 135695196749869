// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/update_store.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_dbmanagement_config_group } from "./config_group_pb.js";
import { file_dbmanagement_config_store } from "./config_store_pb.js";
import { file_dbmanagement_store_info } from "./store_info_pb.js";

/**
 * Describes the file dbmanagement/update_store.proto.
 */
export const file_dbmanagement_update_store =
  /*@__PURE__*/
  fileDesc(
    "Ch9kYm1hbmFnZW1lbnQvdXBkYXRlX3N0b3JlLnByb3RvEhRzdGF0ZWx5LmRibWFuYWdlbWVudCKaAQoSVXBkYXRlU3RvcmVSZXF1ZXN0EhAKCHN0b3JlX2lkGAEgASgEEjgKDXN0b3JlX3VwZGF0ZXMYAiADKAsyIS5zdGF0ZWx5LmRibWFuYWdlbWVudC5TdG9yZVVwZGF0ZRI4Cg1ncm91cF9jb25maWdzGAMgAygLMiEuc3RhdGVseS5kYm1hbmFnZW1lbnQuR3JvdXBDb25maWciRwoTVXBkYXRlU3RvcmVSZXNwb25zZRIwCgVzdG9yZRgBIAEoCzIhLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlN0b3JlQ29uZmlnIt8CCgtTdG9yZVVwZGF0ZRI3Cgt1cGRhdGVfbmFtZRgBIAEoCzIgLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlVwZGF0ZU5hbWVIABJFChJ1cGRhdGVfZGVzY3JpcHRpb24YAiABKAsyJy5zdGF0ZWx5LmRibWFuYWdlbWVudC5VcGRhdGVEZXNjcmlwdGlvbkgAEjUKB2JhY2t1cHMYAyABKAsyIi5zdGF0ZWx5LmRibWFuYWdlbWVudC5CYWNrdXBDb25maWdIABJKChVyZXF1ZXN0X3JhdGVfbGltaXRpbmcYBCABKAsyKS5zdGF0ZWx5LmRibWFuYWdlbWVudC5SZXF1ZXN0UmF0ZUxpbWl0aW5nSAASQwoOdGFibGVfYWNjZXNzb3IYBSABKAsyKS5zdGF0ZWx5LmRibWFuYWdlbWVudC5DdXN0b21UYWJsZUFjY2Vzc29ySABCCAoGdXBkYXRlIhoKClVwZGF0ZU5hbWUSDAoEbmFtZRgCIAEoCSIoChFVcGRhdGVEZXNjcmlwdGlvbhITCgtkZXNjcmlwdGlvbhgBIAEoCUKdAQoYY29tLnN0YXRlbHkuZGJtYW5hZ2VtZW50QhBVcGRhdGVTdG9yZVByb3RvUAGiAgNTRFiqAhRTdGF0ZWx5LkRibWFuYWdlbWVudMoCFFN0YXRlbHlcRGJtYW5hZ2VtZW504gIgU3RhdGVseVxEYm1hbmFnZW1lbnRcR1BCTWV0YWRhdGHqAhVTdGF0ZWx5OjpEYm1hbmFnZW1lbnRiBnByb3RvMw",
    [file_dbmanagement_config_group, file_dbmanagement_config_store, file_dbmanagement_store_info],
  );

/**
 * Describes the message stately.dbmanagement.UpdateStoreRequest.
 * Use `create(UpdateStoreRequestSchema)` to create a new message.
 */
export const UpdateStoreRequestSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_update_store, 0);

/**
 * Describes the message stately.dbmanagement.UpdateStoreResponse.
 * Use `create(UpdateStoreResponseSchema)` to create a new message.
 */
export const UpdateStoreResponseSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_update_store, 1);

/**
 * Describes the message stately.dbmanagement.StoreUpdate.
 * Use `create(StoreUpdateSchema)` to create a new message.
 */
export const StoreUpdateSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_update_store, 2);

/**
 * Describes the message stately.dbmanagement.UpdateName.
 * Use `create(UpdateNameSchema)` to create a new message.
 */
export const UpdateNameSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_update_store, 3);

/**
 * Describes the message stately.dbmanagement.UpdateDescription.
 * Use `create(UpdateDescriptionSchema)` to create a new message.
 */
export const UpdateDescriptionSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_update_store, 4);
