import { Flex } from "@radix-ui/themes";
import { useId, useState } from "react";
import { Region } from "services/api/dbmanagement/region_pb.js";
import { regionToString } from "utils/region";

const regions = [Region.AWS_US_WEST_2, Region.AWS_US_EAST_1];

export function useRegionSelect(init = regions[0]) {
  const [region, setRegion] = useState<Region>(init);
  const id = useId();

  return [
    region,
    <Flex key="select" direction="row" gap="2">
      <label htmlFor={`${id}-select-region`}>Region</label>
      <select id={`${id}-select-region`} onChange={(e) => setRegion(Number(e.currentTarget.value))}>
        {regions.map((r) => (
          <option key={r} value={r} selected={r === region}>
            {regionToString(r)}
          </option>
        ))}
      </select>
    </Flex>,
  ] as const;
}
