// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/describe_store.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_dbmanagement_store_info } from "./store_info_pb.js";

/**
 * Describes the file dbmanagement/describe_store.proto.
 */
export const file_dbmanagement_describe_store =
  /*@__PURE__*/
  fileDesc(
    "CiFkYm1hbmFnZW1lbnQvZGVzY3JpYmVfc3RvcmUucHJvdG8SFHN0YXRlbHkuZGJtYW5hZ2VtZW50IigKFERlc2NyaWJlU3RvcmVSZXF1ZXN0EhAKCHN0b3JlX2lkGAEgASgEIoABChVEZXNjcmliZVN0b3JlUmVzcG9uc2USMAoFc3RvcmUYASABKAsyIS5zdGF0ZWx5LmRibWFuYWdlbWVudC5TdG9yZUNvbmZpZxI1Cgt1c2FnZV9zdGF0cxgCIAEoCzIgLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlVzYWdlU3RhdHNCnwEKGGNvbS5zdGF0ZWx5LmRibWFuYWdlbWVudEISRGVzY3JpYmVTdG9yZVByb3RvUAGiAgNTRFiqAhRTdGF0ZWx5LkRibWFuYWdlbWVudMoCFFN0YXRlbHlcRGJtYW5hZ2VtZW504gIgU3RhdGVseVxEYm1hbmFnZW1lbnRcR1BCTWV0YWRhdGHqAhVTdGF0ZWx5OjpEYm1hbmFnZW1lbnRiBnByb3RvMw",
    [file_dbmanagement_store_info],
  );

/**
 * Describes the message stately.dbmanagement.DescribeStoreRequest.
 * Use `create(DescribeStoreRequestSchema)` to create a new message.
 */
export const DescribeStoreRequestSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_describe_store, 0);

/**
 * Describes the message stately.dbmanagement.DescribeStoreResponse.
 * Use `create(DescribeStoreResponseSchema)` to create a new message.
 */
export const DescribeStoreResponseSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_describe_store, 1);
