// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/list_audit_log.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_schemaservice_schema } from "./schema_pb.js";

/**
 * Describes the file schemaservice/list_audit_log.proto.
 */
export const file_schemaservice_list_audit_log =
  /*@__PURE__*/
  fileDesc(
    "CiJzY2hlbWFzZXJ2aWNlL2xpc3RfYXVkaXRfbG9nLnByb3RvEhVzdGF0ZWx5LnNjaGVtYXNlcnZpY2UiPQoTTGlzdEF1ZGl0TG9nUmVxdWVzdBIRCglzY2hlbWFfaWQYBCABKAQSDQoFbGltaXQYAyABKA1KBAgCEAMiUwoUTGlzdEF1ZGl0TG9nUmVzcG9uc2USOwoHZW50cmllcxgBIAMoCzIqLnN0YXRlbHkuc2NoZW1hc2VydmljZS5TY2hlbWFBdWRpdExvZ0VudHJ5Ir0BChNTY2hlbWFBdWRpdExvZ0VudHJ5EjIKBnNjaGVtYRgBIAEoCzIiLnN0YXRlbHkuc2NoZW1hc2VydmljZS5TY2hlbWFNb2RlbBIaChJtb2RpZmllZF9hdF9taWNyb3MYAiABKAQSEwoLbW9kaWZpZWRfYnkYAyABKAkSGgoSY2hhbmdlX2Rlc2NyaXB0aW9uGAQgASgJEiUKHWJhY2t3YXJkc19pbmNvbXBhdGlibGVfY2hhbmdlGAUgASgIQqMBChljb20uc3RhdGVseS5zY2hlbWFzZXJ2aWNlQhFMaXN0QXVkaXRMb2dQcm90b1ABogIDU1NYqgIVU3RhdGVseS5TY2hlbWFzZXJ2aWNlygIVU3RhdGVseVxTY2hlbWFzZXJ2aWNl4gIhU3RhdGVseVxTY2hlbWFzZXJ2aWNlXEdQQk1ldGFkYXRh6gIWU3RhdGVseTo6U2NoZW1hc2VydmljZWIGcHJvdG8z",
    [file_schemaservice_schema],
  );

/**
 * Describes the message stately.schemaservice.ListAuditLogRequest.
 * Use `create(ListAuditLogRequestSchema)` to create a new message.
 */
export const ListAuditLogRequestSchema =
  /*@__PURE__*/
  messageDesc(file_schemaservice_list_audit_log, 0);

/**
 * Describes the message stately.schemaservice.ListAuditLogResponse.
 * Use `create(ListAuditLogResponseSchema)` to create a new message.
 */
export const ListAuditLogResponseSchema =
  /*@__PURE__*/
  messageDesc(file_schemaservice_list_audit_log, 1);

/**
 * Describes the message stately.schemaservice.SchemaAuditLogEntry.
 * Use `create(SchemaAuditLogEntrySchema)` to create a new message.
 */
export const SchemaAuditLogEntrySchema =
  /*@__PURE__*/
  messageDesc(file_schemaservice_list_audit_log, 2);
