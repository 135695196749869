import { Box, Flex } from "@radix-ui/themes";
import { Dispatch, SetStateAction, useId } from "react";
import { AlignLabel, alignLabelToDirection } from "ui/shared/alignLabel";
import { checkboxChangeHandler } from "ui/utils/forms";
import styles from "./CheckBox.m.css";

export default function CheckBox({
  label,
  onChange,
  alignLabel = "right",
  inputRef,
  ...props
}: Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> & {
  label?: React.ReactNode;
  alignLabel?: AlignLabel;
  onChange: Dispatch<SetStateAction<boolean>>;
  inputRef?: React.Ref<HTMLInputElement>;
}) {
  const id = useId();
  const handleChange = checkboxChangeHandler(onChange);
  return (
    <Flex direction={alignLabelToDirection[alignLabel]} display="inline-flex" gap="2">
      <Box flexGrow="1">
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      </Box>
      <Box>
        <input ref={inputRef} id={id} type="checkbox" {...props} onChange={handleChange} />
      </Box>
    </Flex>
  );
}
