import { Dispatch, SetStateAction, useCallback, useState } from "react";

export function textChangeHandler(setValue: Dispatch<SetStateAction<string>>) {
  return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setValue(e.target.value);
}

export function checkboxChangeHandler(setValue: Dispatch<SetStateAction<boolean>>) {
  return (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.checked);
}

export function useSubmitFormHandler(handler: () => Promise<void>, close: () => void) {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      try {
        await handler();
        close();
      } catch (e) {
        setError(e instanceof Error ? e : new Error("Unknown error"));
      } finally {
        setLoading(false);
      }
    },
    [handler, close],
  );

  const resetError = useCallback(() => setError(undefined), []);

  return [loading, handleSubmit, error, resetError] as const;
}
