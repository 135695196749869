import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styles from "./CopyText.m.css";

/**
 * Show some text that's easy to copy to the clipboard.
 */
export default function CopyText({ text }: { text: string }) {
  const [copied, setCopied] = useState(false);
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => e.target.select();

  const handleButtonClick = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  return (
    <div className={styles.container}>
      <input onFocus={handleFocus} value={text ?? ""} readOnly />
      <button type="button" onClick={handleButtonClick}>
        <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
      </button>
    </div>
  );
}
