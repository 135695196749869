// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/describe_access_key.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/describe_access_key.proto.
 */
export const file_user_describe_access_key =
  /*@__PURE__*/
  fileDesc(
    "Ch51c2VyL2Rlc2NyaWJlX2FjY2Vzc19rZXkucHJvdG8SDHN0YXRlbHkudXNlciIxChhEZXNjcmliZUFjY2Vzc0tleVJlcXVlc3QSFQoNYWNjZXNzX2tleV9pZBgBIAEoBCJrChlEZXNjcmliZUFjY2Vzc0tleVJlc3BvbnNlEhUKDWFjY2Vzc19rZXlfaWQYASABKAQSDAoEbmFtZRgCIAEoCRIVCg1jcmVhdGVkX2F0X21zGAMgASgEEhIKCmFjY2Vzc19rZXkYBCABKAlCewoQY29tLnN0YXRlbHkudXNlckIWRGVzY3JpYmVBY2Nlc3NLZXlQcm90b1ABogIDU1VYqgIMU3RhdGVseS5Vc2VyygIMU3RhdGVseVxVc2Vy4gIYU3RhdGVseVxVc2VyXEdQQk1ldGFkYXRh6gINU3RhdGVseTo6VXNlcmIGcHJvdG8z",
  );

/**
 * Describes the message stately.user.DescribeAccessKeyRequest.
 * Use `create(DescribeAccessKeyRequestSchema)` to create a new message.
 */
export const DescribeAccessKeyRequestSchema =
  /*@__PURE__*/
  messageDesc(file_user_describe_access_key, 0);

/**
 * Describes the message stately.user.DescribeAccessKeyResponse.
 * Use `create(DescribeAccessKeyResponseSchema)` to create a new message.
 */
export const DescribeAccessKeyResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_describe_access_key, 1);
