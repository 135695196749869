// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/delete_access_key.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/delete_access_key.proto.
 */
export const file_user_delete_access_key =
  /*@__PURE__*/
  fileDesc(
    "Chx1c2VyL2RlbGV0ZV9hY2Nlc3Nfa2V5LnByb3RvEgxzdGF0ZWx5LnVzZXIiLwoWRGVsZXRlQWNjZXNzS2V5UmVxdWVzdBIVCg1hY2Nlc3Nfa2V5X2lkGAEgASgEIhkKF0RlbGV0ZUFjY2Vzc0tleVJlc3BvbnNlQnkKEGNvbS5zdGF0ZWx5LnVzZXJCFERlbGV0ZUFjY2Vzc0tleVByb3RvUAGiAgNTVViqAgxTdGF0ZWx5LlVzZXLKAgxTdGF0ZWx5XFVzZXLiAhhTdGF0ZWx5XFVzZXJcR1BCTWV0YWRhdGHqAg1TdGF0ZWx5OjpVc2VyYgZwcm90bzM",
  );

/**
 * Describes the message stately.user.DeleteAccessKeyRequest.
 * Use `create(DeleteAccessKeyRequestSchema)` to create a new message.
 */
export const DeleteAccessKeyRequestSchema =
  /*@__PURE__*/
  messageDesc(file_user_delete_access_key, 0);

/**
 * Describes the message stately.user.DeleteAccessKeyResponse.
 * Use `create(DeleteAccessKeyResponseSchema)` to create a new message.
 */
export const DeleteAccessKeyResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_delete_access_key, 1);
