// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/schema_info.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file schemaservice/schema_info.proto.
 */
export const file_schemaservice_schema_info =
  /*@__PURE__*/
  fileDesc(
    "Ch9zY2hlbWFzZXJ2aWNlL3NjaGVtYV9pbmZvLnByb3RvEhVzdGF0ZWx5LnNjaGVtYXNlcnZpY2UiQgoKU2NoZW1hSW5mbxIRCglzY2hlbWFfaWQYASABKAQSDAoEbmFtZRgDIAEoCRITCgtkZXNjcmlwdGlvbhgEIAEoCUKhAQoZY29tLnN0YXRlbHkuc2NoZW1hc2VydmljZUIPU2NoZW1hSW5mb1Byb3RvUAGiAgNTU1iqAhVTdGF0ZWx5LlNjaGVtYXNlcnZpY2XKAhVTdGF0ZWx5XFNjaGVtYXNlcnZpY2XiAiFTdGF0ZWx5XFNjaGVtYXNlcnZpY2VcR1BCTWV0YWRhdGHqAhZTdGF0ZWx5OjpTY2hlbWFzZXJ2aWNlYgZwcm90bzM",
  );

/**
 * Describes the message stately.schemaservice.SchemaInfo.
 * Use `create(SchemaInfoSchema)` to create a new message.
 */
export const SchemaInfoSchema = /*@__PURE__*/ messageDesc(file_schemaservice_schema_info, 0);
