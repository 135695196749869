import { createSelector } from "reselect";
import { SchemaID, StoreID } from "services/data/data";
import { OrganizationID, ProjectID } from "services/user";
import { useUserClient } from "services/user/useUserClient";
import { suspend } from "suspend-react";
import { emptyArray } from "utils/empty";
import { useUserStore } from "./state";
import { Project, Schema, Store } from "./types";

type UserState = ReturnType<typeof useUserStore.getState>;

const useLoadUserFn = simpleHook((state) => state.loadUser);
export const useWhoamiError = simpleHook((state) => state.whoamiError);
export const useCreateStore = simpleHook((state) => state.createStore);
export const useDeleteStore = simpleHook((state) => state.deleteStore);
export const useUpdateOrgName = simpleHook((state) => state.updateOrgName);
export const useUpdateStoreName = simpleHook((state) => state.updateStoreName);

/** Load the user info the first time. */
export function useLoadUser() {
  const userClient = useUserClient();
  const loadUser = useLoadUserFn();
  suspend(() => loadUser(userClient), ["loadUser"]);
}

const whoamiLoadedSelector = (state: UserState) => state.whoamiLoaded;
export const useWhoamiLoaded = simpleHook(whoamiLoadedSelector);

const organizationsMapSelector = (state: UserState) => state.organizations;
const projectsMapSelector = (state: UserState) => state.projects;
const storesMapSelector = (state: UserState) => state.stores;
const schemasMapSelector = (state: UserState) => state.schemas;

export const useStoreInfo = (storeId: StoreID) =>
  useUserStore((state) => state.stores.get(storeId));

export const useProjectInfo = (projectId: ProjectID) =>
  useUserStore((state) => state.projects.get(projectId));

export const useOrganizationInfo = (orgId: OrganizationID) =>
  useUserStore((state) => state.organizations.get(orgId));

export const useSchemaInfo = (schemaId: SchemaID) =>
  useUserStore((state) => state.schemas.get(schemaId));

export const useIsAdmin = () => useUserStore((state) => state.user?.isAdmin ?? false);

/**
 * A list of all organizations.
 */
export const useOrganizations = simpleHook(
  createSelector(
    organizationsMapSelector,
    // TODO: Sort these more usefully somehow? By last accessed? Creation date?
    (orgMap) => [...orgMap.values()].sort((a, b) => a.name.localeCompare(b.name)),
  ),
);

/** Does a user have any stores?
 */
export const useHasStores = simpleHook(
  createSelector(storesMapSelector, (stores) => stores.size > 0),
);

/** Does a user have any schemas?
 */
export const useHasSchemas = simpleHook(
  createSelector(schemasMapSelector, (schemas) => schemas.size > 0),
);

const projectsByOrgIdSelector = createSelector(projectsMapSelector, (projects) =>
  Map.groupBy(projects.values(), (p) => p.organizationId),
);

export const useProjectsForOrg = (orgId: OrganizationID) =>
  useUserStore((state) => projectsByOrgIdSelector(state).get(orgId) ?? emptyArray<Project>());

const storesByProjectIdSelector = createSelector(storesMapSelector, (stores) =>
  Map.groupBy(stores.values(), (s) => s.projectId),
);

export const useStoresForProject = (projectId: ProjectID) =>
  useUserStore((state) => storesByProjectIdSelector(state).get(projectId) ?? emptyArray<Store>());

const schemasByProjectIdSelector = createSelector(schemasMapSelector, (schemas) =>
  Map.groupBy(schemas.values(), (s) => s.projectId),
);

export const useSchemasForProject = (projectId: ProjectID) =>
  useUserStore((state) => schemasByProjectIdSelector(state).get(projectId) ?? emptyArray<Schema>());

/** Simplifies declaring simple selector hooks. */
function simpleHook<T>(selector: (state: UserState) => T) {
  return () => useUserStore(selector);
}
