import { faCancel, faPencil, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertDialog, Button, Flex, TextField } from "@radix-ui/themes";
import { useEffect, useState } from "react";

export default function EditableText({
  text,
  onEdit,
  className,
  label,
  failureTitle,
  failureMessage,
}: {
  text: string;
  className?: string;
  label: string;
  failureTitle: string;
  failureMessage: string;
  onEdit: (text: string) => Promise<void>;
}) {
  const [editing, setEditing] = useState(false);
  const [newText, setNewText] = useState(text);

  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    setNewText(text);
  }, [text]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewText(e.target.value);
  };
  const handleStartEditing = () => {
    setEditing(true);
  };
  const handleCancel = () => {
    setEditing(false);
    setNewText(text);
  };
  const handleSave = async () => {
    try {
      setUpdating(true);
      await onEdit(newText);
      setEditing(false);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      }
    } finally {
      setUpdating(false);
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSave();
    } else if (e.key === "Escape") {
      handleCancel();
    }
  };

  return (
    <>
      {editing ? (
        <TextField.Root
          className={className}
          value={newText}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <span className={className}>{text}</span>
      )}
      {editing ? (
        <>
          <Button size="1" color="gray" variant="soft" onClick={handleCancel} disabled={updating}>
            <FontAwesomeIcon icon={faCancel} aria-hidden="true" /> Cancel
          </Button>
          <Button size="1" color="gray" variant="soft" onClick={handleSave}>
            <FontAwesomeIcon icon={faSave} aria-hidden="true" /> Save
          </Button>
        </>
      ) : (
        <Button
          size="1"
          color="gray"
          variant="soft"
          onClick={handleStartEditing}
          disabled={updating}
        >
          <FontAwesomeIcon icon={faPencil} aria-hidden="true" /> {label}
        </Button>
      )}
      {error && (
        <AlertDialog.Root
          open={true}
          onOpenChange={(open) => {
            if (!open) {
              setError(undefined);
            }
          }}
        >
          <AlertDialog.Content>
            <AlertDialog.Title>{failureTitle}</AlertDialog.Title>
            <AlertDialog.Description>{failureMessage}:</AlertDialog.Description>
            <AlertDialog.Description>{error.message}</AlertDialog.Description>

            <Flex gap="3" mt="4" justify="end">
              <AlertDialog.Cancel>
                <Button>Dang</Button>
              </AlertDialog.Cancel>
            </Flex>
          </AlertDialog.Content>
        </AlertDialog.Root>
      )}
    </>
  );
}
