import { faDatabase, faShapes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Flex, Heading, Tabs, Text, Tooltip } from "@radix-ui/themes";
import { Link } from "react-router";
import { useManagementClient } from "services/management/useManagementClient";
import { useSchemaInfo, useUpdateStoreName } from "state/user/hooks";
import { Store } from "state/user/types";
import TerminalSnippet from "ui/shared/TerminalSnippet";
import EditableText from "ui/utils/EditableText";
import { regionToString } from "utils/region";
import styles from "./StoreTile.m.css";

export function StoreTile({ store }: { store: Store }) {
  const schema = useSchemaInfo(store.schemaId);
  const updateStoreName = useUpdateStoreName();
  const managementClient = useManagementClient();

  const handleEditName = async (name: string) => {
    await updateStoreName(managementClient, store.storeId, name);
  };

  return (
    <Card>
      <Flex direction="column" gap="4">
        <Flex direction="row" justify="between">
          <Flex direction="column" gap="2">
            <Heading size="4" trim="end" className={styles.title}>
              <FontAwesomeIcon icon={faDatabase} />
              Store:
              <EditableText
                text={store.name}
                onEdit={handleEditName}
                className={styles.text}
                label="Edit Name"
                failureMessage="We weren't able to update this store's name"
                failureTitle="Failed to update store name"
              />
            </Heading>
            <Flex direction="column">
              <Text>
                <b>Store ID:</b> {store.storeId.toString()}
              </Text>
              {schema && (
                <Text>
                  <b>Schema:</b> <Link to={`/schema/${schema.schemaId}`}>{schema.name}</Link>
                </Text>
              )}
              <Text>
                <b>Region:</b> {regionToString(store.defaultRegion)}
              </Text>
            </Flex>
          </Flex>
          <Flex direction="row" gap="2">
            {/* <Link id="data-browser-link" to={`/store/${store.storeId}/browse`}>
              <Button variant="solid">
                <FontAwesomeIcon icon={faTable} />
                Explore Items
              </Button>
            </Link> */}
            {schema ? (
              <Link id="schema-link" to={`/schema/${schema.schemaId}`}>
                <Button variant="soft">
                  <FontAwesomeIcon icon={faShapes} />
                  View Schema
                </Button>
              </Link>
            ) : (
              <Button variant="solid">
                <FontAwesomeIcon icon={faShapes} />
                Bind Schema
              </Button>
            )}
            <Tooltip
              delayDuration={0}
              content="Contact support@stately.cloud for help with your Store settings."
            >
              <Button variant="soft" disabled>
                <FontAwesomeIcon icon={faDatabase} />
                Manage Store
              </Button>
            </Tooltip>
          </Flex>
        </Flex>

        <details>
          <summary>Client setup instructions</summary>

          <Tabs.Root className="TabsRoot" defaultValue="javascript">
            <Tabs.List className="TabsList" aria-label="Setup your client">
              <Tabs.Trigger className="TabsTrigger" value="javascript">
                Javascript
              </Tabs.Trigger>
              <Tabs.Trigger className="TabsTrigger" value="golang">
                Golang
              </Tabs.Trigger>
              <Tabs.Trigger className="TabsTrigger" value="ruby">
                Ruby
              </Tabs.Trigger>
              <Tabs.Trigger className="TabsTrigger" value="python">
                Python
              </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content className="TabsContent" value="javascript">
              <TerminalSnippet language="javascript">
                {`// Import your generated client
import { createClient } from './schema';
const client = createClient(${store.storeId.toString()}n, { region: "${regionToString(store.defaultRegion)}" });`}
              </TerminalSnippet>
            </Tabs.Content>
            <Tabs.Content className="TabsContent" value="golang">
              <TerminalSnippet language="Go">
                {`package client

// Import your generated client
import (
  // Import your generated schema code
  "github.com/myorg/myrepo/generated/schema"
)

func createClient() (stately.Client, error) {
  client, err := schema.NewClient(ctx, stately.StoreID(${store.storeId.toString()}), &stately.Options{ Region: "${regionToString(store.defaultRegion)}" })
  return client, err
}`}
              </TerminalSnippet>
            </Tabs.Content>
            <Tabs.Content className="TabsContent" value="ruby">
              <TerminalSnippet language="Ruby">
                {`# Import your generated client
require_relative 'schema/stately'

client = StatelyDB::Client.new(store_id: ${store.storeId.toString()}), region: "${regionToString(store.defaultRegion)}")`}
              </TerminalSnippet>
            </Tabs.Content>
            <Tabs.Content className="TabsContent" value="python">
              <TerminalSnippet language="Python">
                {`# Import your generated client
from schema import Client

client = Client(store_id=${store.storeId.toString()}), region="${regionToString(store.defaultRegion)}")`}
              </TerminalSnippet>
            </Tabs.Content>
          </Tabs.Root>
          <Text>
            Check out our <a href="https://docs.stately.cloud/guides/connect/">documentation</a> for
            more details.
          </Text>
        </details>
      </Flex>
    </Card>
  );
}
