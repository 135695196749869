import { LinkProps, Link as RadixLink } from "@radix-ui/themes";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router";

interface RadixLinkProps extends LinkProps {
  asChild?: boolean;
}

export default function Link({
  to,
  relative,
  replace,
  children,
  ...props
}: { children: React.ReactNode } & RouterLinkProps & RadixLinkProps) {
  return (
    <RadixLink asChild {...props}>
      <RouterLink to={to} relative={relative} replace={replace}>
        {children}
      </RouterLink>
    </RadixLink>
  );
}
