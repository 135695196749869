// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/create.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file schemaservice/create.proto.
 */
export const file_schemaservice_create =
  /*@__PURE__*/
  fileDesc(
    "ChpzY2hlbWFzZXJ2aWNlL2NyZWF0ZS5wcm90bxIVc3RhdGVseS5zY2hlbWFzZXJ2aWNlIkYKDUNyZWF0ZVJlcXVlc3QSEgoKcHJvamVjdF9pZBgBIAEoBBIMCgRuYW1lGAIgASgJEhMKC2Rlc2NyaXB0aW9uGAMgASgJIiMKDkNyZWF0ZVJlc3BvbnNlEhEKCXNjaGVtYV9pZBgBIAEoBEKdAQoZY29tLnN0YXRlbHkuc2NoZW1hc2VydmljZUILQ3JlYXRlUHJvdG9QAaICA1NTWKoCFVN0YXRlbHkuU2NoZW1hc2VydmljZcoCFVN0YXRlbHlcU2NoZW1hc2VydmljZeICIVN0YXRlbHlcU2NoZW1hc2VydmljZVxHUEJNZXRhZGF0YeoCFlN0YXRlbHk6OlNjaGVtYXNlcnZpY2ViBnByb3RvMw",
  );

/**
 * Describes the message stately.schemaservice.CreateRequest.
 * Use `create(CreateRequestSchema)` to create a new message.
 */
export const CreateRequestSchema = /*@__PURE__*/ messageDesc(file_schemaservice_create, 0);

/**
 * Describes the message stately.schemaservice.CreateResponse.
 * Use `create(CreateResponseSchema)` to create a new message.
 */
export const CreateResponseSchema = /*@__PURE__*/ messageDesc(file_schemaservice_create, 1);
