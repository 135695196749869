import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog, Text } from "@radix-ui/themes";
import { noop } from "es-toolkit";
import { useCallback, useState } from "react";
import { AccessKeyOverview } from "services/api/user/list_access_keys_pb";
import { AccessKeyID, deleteAccessKey } from "services/user";
import { useUserClient } from "services/user/useUserClient";
import DialogButtons from "ui/shared/DialogButtons";
import { useSubmitFormHandler } from "ui/utils/forms";

export type OnAccessKeyDeleted = (keyId: AccessKeyID) => void;

export function DeleteAccessKeyButton({
  ak,
  onAccessKeyDeleted,
}: {
  ak: AccessKeyOverview;
  onAccessKeyDeleted?: OnAccessKeyDeleted;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const close = useCallback(() => setShowDialog(false), []);

  const handleOnAccessKeyDeleted = (keyId: AccessKeyID) => {
    onAccessKeyDeleted?.(keyId);
    close();
  };

  return (
    <>
      <Dialog.Root open={showDialog} onOpenChange={setShowDialog}>
        <Dialog.Trigger>
          <Button variant="soft">
            <FontAwesomeIcon icon={faTrash} aria-hidden="true" />
            <Text>Delete</Text>
          </Button>
        </Dialog.Trigger>
        <Dialog.Content>
          <DeleteAccessKeyForm ak={ak} onAccessKeyDeleted={handleOnAccessKeyDeleted} />
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}

export function DeleteAccessKeyForm({
  ak,
  onAccessKeyDeleted,
}: {
  ak: AccessKeyOverview;
  onAccessKeyDeleted: OnAccessKeyDeleted;
}) {
  const client = useUserClient();
  const [committed, setCommitted] = useState<boolean>(false);

  const [loading, handleSubmit, error] = useSubmitFormHandler(async () => {
    if (!committed) {
      throw new Error("You must check the box to confirm you want to delete this access key.");
    }
    await deleteAccessKey(client, ak.accessKeyId);
    onAccessKeyDeleted(ak.accessKeyId);
  }, noop);

  return (
    <form onSubmit={handleSubmit}>
      <Dialog.Title>Delete access key "{ak.name}"</Dialog.Title>
      <label>
        <input
          type="checkbox"
          checked={committed}
          onChange={(e) => setCommitted(e.currentTarget.checked)}
        />{" "}
        I'm sure I want to delete this access key. I know it'll cause any apps using this key to
        fail to authenticate!
      </label>
      <DialogButtons progress={loading && "Deleting access key..."} error={error}>
        <Dialog.Close>
          <Button variant="soft" color="gray">
            Cancel
          </Button>
        </Dialog.Close>
        <Button type="submit" variant="solid" disabled={loading} color="red">
          Delete Access Key
        </Button>
      </DialogButtons>
    </form>
  );
}
