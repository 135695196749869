// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/whoami.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_dbmanagement_store_info } from "../dbmanagement/store_info_pb.js";
import { file_schemaservice_schema_info } from "../schemaservice/schema_info_pb.js";
import { file_user_organization } from "./organization_pb.js";
import { file_user_project } from "./project_pb.js";

/**
 * Describes the file user/whoami.proto.
 */
export const file_user_whoami =
  /*@__PURE__*/
  fileDesc(
    "ChF1c2VyL3dob2FtaS5wcm90bxIMc3RhdGVseS51c2VyIg8KDVdob2FtaVJlcXVlc3QivwEKDldob2FtaVJlc3BvbnNlEhUKDW9hdXRoX3N1YmplY3QYASABKAkSDwoHdXNlcl9pZBgCIAEoBBINCgVlbWFpbBgDIAEoCRIXCg9lbnJvbGxtZW50X3RpbWUYBCABKAQSFAoMZGlzcGxheV9uYW1lGAUgASgJEjUKDW9yZ2FuaXphdGlvbnMYBiADKAsyHi5zdGF0ZWx5LnVzZXIuT3JnYW5pemF0aW9uTm9kZRIQCghpc19hZG1pbhgHIAEoCCJxChBPcmdhbml6YXRpb25Ob2RlEjAKDG9yZ2FuaXphdGlvbhgBIAEoCzIaLnN0YXRlbHkudXNlci5Pcmdhbml6YXRpb24SKwoIcHJvamVjdHMYAiADKAsyGS5zdGF0ZWx5LnVzZXIuUHJvamVjdE5vZGUikgEKC1Byb2plY3ROb2RlEiYKB3Byb2plY3QYASABKAsyFS5zdGF0ZWx5LnVzZXIuUHJvamVjdBInCgZzdG9yZXMYAiADKAsyFy5zdGF0ZWx5LnVzZXIuU3RvcmVOb2RlEjIKB3NjaGVtYXMYAyADKAsyIS5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuU2NoZW1hSW5mbyI7CglTdG9yZU5vZGUSLgoFc3RvcmUYASABKAsyHy5zdGF0ZWx5LmRibWFuYWdlbWVudC5TdG9yZUluZm9CcAoQY29tLnN0YXRlbHkudXNlckILV2hvYW1pUHJvdG9QAaICA1NVWKoCDFN0YXRlbHkuVXNlcsoCDFN0YXRlbHlcVXNlcuICGFN0YXRlbHlcVXNlclxHUEJNZXRhZGF0YeoCDVN0YXRlbHk6OlVzZXJiBnByb3RvMw",
    [
      file_dbmanagement_store_info,
      file_schemaservice_schema_info,
      file_user_organization,
      file_user_project,
    ],
  );

/**
 * Describes the message stately.user.WhoamiRequest.
 * Use `create(WhoamiRequestSchema)` to create a new message.
 */
export const WhoamiRequestSchema = /*@__PURE__*/ messageDesc(file_user_whoami, 0);

/**
 * Describes the message stately.user.WhoamiResponse.
 * Use `create(WhoamiResponseSchema)` to create a new message.
 */
export const WhoamiResponseSchema = /*@__PURE__*/ messageDesc(file_user_whoami, 1);

/**
 * Describes the message stately.user.OrganizationNode.
 * Use `create(OrganizationNodeSchema)` to create a new message.
 */
export const OrganizationNodeSchema = /*@__PURE__*/ messageDesc(file_user_whoami, 2);

/**
 * Describes the message stately.user.ProjectNode.
 * Use `create(ProjectNodeSchema)` to create a new message.
 */
export const ProjectNodeSchema = /*@__PURE__*/ messageDesc(file_user_whoami, 3);

/**
 * Describes the message stately.user.StoreNode.
 * Use `create(StoreNodeSchema)` to create a new message.
 */
export const StoreNodeSchema = /*@__PURE__*/ messageDesc(file_user_whoami, 4);
