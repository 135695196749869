// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/update_project.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_dbmanagement_update_store } from "../dbmanagement/update_store_pb.js";
import { file_user_project } from "./project_pb.js";

/**
 * Describes the file user/update_project.proto.
 */
export const file_user_update_project =
  /*@__PURE__*/
  fileDesc(
    "Chl1c2VyL3VwZGF0ZV9wcm9qZWN0LnByb3RvEgxzdGF0ZWx5LnVzZXIiWAoUVXBkYXRlUHJvamVjdFJlcXVlc3QSEgoKcHJvamVjdF9pZBgBIAEoBBIsCgd1cGRhdGVzGAIgAygLMhsuc3RhdGVseS51c2VyLlByb2plY3RVcGRhdGUiPwoVVXBkYXRlUHJvamVjdFJlc3BvbnNlEiYKB3Byb2plY3QYASABKAsyFS5zdGF0ZWx5LnVzZXIuUHJvamVjdCKZAQoNUHJvamVjdFVwZGF0ZRI3Cgt1cGRhdGVfbmFtZRgBIAEoCzIgLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlVwZGF0ZU5hbWVIABJFChJ1cGRhdGVfZGVzY3JpcHRpb24YAiABKAsyJy5zdGF0ZWx5LmRibWFuYWdlbWVudC5VcGRhdGVEZXNjcmlwdGlvbkgAQggKBnVwZGF0ZUJ3ChBjb20uc3RhdGVseS51c2VyQhJVcGRhdGVQcm9qZWN0UHJvdG9QAaICA1NVWKoCDFN0YXRlbHkuVXNlcsoCDFN0YXRlbHlcVXNlcuICGFN0YXRlbHlcVXNlclxHUEJNZXRhZGF0YeoCDVN0YXRlbHk6OlVzZXJiBnByb3RvMw",
    [file_dbmanagement_update_store, file_user_project],
  );

/**
 * Describes the message stately.user.UpdateProjectRequest.
 * Use `create(UpdateProjectRequestSchema)` to create a new message.
 */
export const UpdateProjectRequestSchema = /*@__PURE__*/ messageDesc(file_user_update_project, 0);

/**
 * Describes the message stately.user.UpdateProjectResponse.
 * Use `create(UpdateProjectResponseSchema)` to create a new message.
 */
export const UpdateProjectResponseSchema = /*@__PURE__*/ messageDesc(file_user_update_project, 1);

/**
 * Describes the message stately.user.ProjectUpdate.
 * Use `create(ProjectUpdateSchema)` to create a new message.
 */
export const ProjectUpdateSchema = /*@__PURE__*/ messageDesc(file_user_update_project, 2);
