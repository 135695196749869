// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file migration.proto (package stately.schemamodel, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file migration.proto.
 */
export const file_migration =
  /*@__PURE__*/
  fileDesc(
    "Cg9taWdyYXRpb24ucHJvdG8SE3N0YXRlbHkuc2NoZW1hbW9kZWwibwoJTWlncmF0aW9uEhsKE2Zyb21fc2NoZW1hX3ZlcnNpb24YASABKAQSDAoEbmFtZRgCIAEoCRI3Cghjb21tYW5kcxgDIAMoCzIlLnN0YXRlbHkuc2NoZW1hbW9kZWwuTWlncmF0aW9uQ29tbWFuZCJaChBNaWdyYXRpb25Db21tYW5kEhEKCXR5cGVfbmFtZRgBIAEoCRIzCgdhY3Rpb25zGAIgAygLMiIuc3RhdGVseS5zY2hlbWFtb2RlbC5NaWdyYXRlQWN0aW9uIuMCCg1NaWdyYXRlQWN0aW9uEjIKCWFkZF9maWVsZBgCIAEoCzIdLnN0YXRlbHkuc2NoZW1hbW9kZWwuQWRkRmllbGRIABI4CgxyZW1vdmVfZmllbGQYAyABKAsyIC5zdGF0ZWx5LnNjaGVtYW1vZGVsLlJlbW92ZUZpZWxkSAASOAoMcmVuYW1lX2ZpZWxkGAQgASgLMiAuc3RhdGVseS5zY2hlbWFtb2RlbC5SZW5hbWVGaWVsZEgAEjYKC3JlbmFtZV90eXBlGAYgASgLMh8uc3RhdGVseS5zY2hlbWFtb2RlbC5SZW5hbWVUeXBlSAASNgoLcmVtb3ZlX3R5cGUYByABKAsyHy5zdGF0ZWx5LnNjaGVtYW1vZGVsLlJlbW92ZVR5cGVIABIwCghhZGRfdHlwZRgIIAEoCzIcLnN0YXRlbHkuc2NoZW1hbW9kZWwuQWRkVHlwZUgAQggKBmFjdGlvbiIYCghBZGRGaWVsZBIMCgRuYW1lGAEgASgJIhsKC1JlbW92ZUZpZWxkEgwKBG5hbWUYASABKAkiMQoLUmVuYW1lRmllbGQSEAoIb2xkX25hbWUYASABKAkSEAoIbmV3X25hbWUYAiABKAkiHgoKUmVuYW1lVHlwZRIQCghuZXdfbmFtZRgCIAEoCSIMCgpSZW1vdmVUeXBlIhcKB0FkZFR5cGUSDAoEbmFtZRgBIAEoCUKWAQoXY29tLnN0YXRlbHkuc2NoZW1hbW9kZWxCDk1pZ3JhdGlvblByb3RvUAGiAgNTU1iqAhNTdGF0ZWx5LlNjaGVtYW1vZGVsygITU3RhdGVseVxTY2hlbWFtb2RlbOICH1N0YXRlbHlcU2NoZW1hbW9kZWxcR1BCTWV0YWRhdGHqAhRTdGF0ZWx5OjpTY2hlbWFtb2RlbGIGcHJvdG8z",
  );

/**
 * Describes the message stately.schemamodel.Migration.
 * Use `create(MigrationSchema)` to create a new message.
 */
export const MigrationSchema = /*@__PURE__*/ messageDesc(file_migration, 0);

/**
 * Describes the message stately.schemamodel.MigrationCommand.
 * Use `create(MigrationCommandSchema)` to create a new message.
 */
export const MigrationCommandSchema = /*@__PURE__*/ messageDesc(file_migration, 1);

/**
 * Describes the message stately.schemamodel.MigrateAction.
 * Use `create(MigrateActionSchema)` to create a new message.
 */
export const MigrateActionSchema = /*@__PURE__*/ messageDesc(file_migration, 2);

/**
 * Describes the message stately.schemamodel.AddField.
 * Use `create(AddFieldSchema)` to create a new message.
 */
export const AddFieldSchema = /*@__PURE__*/ messageDesc(file_migration, 3);

/**
 * Describes the message stately.schemamodel.RemoveField.
 * Use `create(RemoveFieldSchema)` to create a new message.
 */
export const RemoveFieldSchema = /*@__PURE__*/ messageDesc(file_migration, 4);

/**
 * Describes the message stately.schemamodel.RenameField.
 * Use `create(RenameFieldSchema)` to create a new message.
 */
export const RenameFieldSchema = /*@__PURE__*/ messageDesc(file_migration, 5);

/**
 * Describes the message stately.schemamodel.RenameType.
 * Use `create(RenameTypeSchema)` to create a new message.
 */
export const RenameTypeSchema = /*@__PURE__*/ messageDesc(file_migration, 6);

/**
 * Describes the message stately.schemamodel.RemoveType.
 * Use `create(RemoveTypeSchema)` to create a new message.
 */
export const RemoveTypeSchema = /*@__PURE__*/ messageDesc(file_migration, 7);

/**
 * Describes the message stately.schemamodel.AddType.
 * Use `create(AddTypeSchema)` to create a new message.
 */
export const AddTypeSchema = /*@__PURE__*/ messageDesc(file_migration, 8);
