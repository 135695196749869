// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/project.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/project.proto.
 */
export const file_user_project =
  /*@__PURE__*/
  fileDesc(
    "ChJ1c2VyL3Byb2plY3QucHJvdG8SDHN0YXRlbHkudXNlciJZCgdQcm9qZWN0EhIKCnByb2plY3RfaWQYASABKAQSDAoEbmFtZRgCIAEoCRITCgtkZXNjcmlwdGlvbhgDIAEoCRIXCg9vcmdhbml6YXRpb25faWQYBCABKARCcQoQY29tLnN0YXRlbHkudXNlckIMUHJvamVjdFByb3RvUAGiAgNTVViqAgxTdGF0ZWx5LlVzZXLKAgxTdGF0ZWx5XFVzZXLiAhhTdGF0ZWx5XFVzZXJcR1BCTWV0YWRhdGHqAg1TdGF0ZWx5OjpVc2VyYgZwcm90bzM",
  );

/**
 * Describes the message stately.user.Project.
 * Use `create(ProjectSchema)` to create a new message.
 */
export const ProjectSchema = /*@__PURE__*/ messageDesc(file_user_project, 0);
