// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/region.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file dbmanagement/region.proto.
 */
export const file_dbmanagement_region =
  /*@__PURE__*/
  fileDesc(
    "ChlkYm1hbmFnZW1lbnQvcmVnaW9uLnByb3RvEhRzdGF0ZWx5LmRibWFuYWdlbWVudCpOCgZSZWdpb24SEAoMUkVHSU9OX1VOU0VUEAASGAoUUkVHSU9OX0FXU19VU19FQVNUXzEQARIYChRSRUdJT05fQVdTX1VTX1dFU1RfMhACQpgBChhjb20uc3RhdGVseS5kYm1hbmFnZW1lbnRCC1JlZ2lvblByb3RvUAGiAgNTRFiqAhRTdGF0ZWx5LkRibWFuYWdlbWVudMoCFFN0YXRlbHlcRGJtYW5hZ2VtZW504gIgU3RhdGVseVxEYm1hbmFnZW1lbnRcR1BCTWV0YWRhdGHqAhVTdGF0ZWx5OjpEYm1hbmFnZW1lbnRiBnByb3RvMw",
  );

/**
 * Describes the enum stately.dbmanagement.Region.
 */
export const RegionSchema = /*@__PURE__*/ enumDesc(file_dbmanagement_region, 0);

/**
 * Region is an enum representing a specific cloud region. This should be kept
 * in sync with env.Region.
 *
 * @generated from enum stately.dbmanagement.Region
 */
export const Region = /*@__PURE__*/ tsEnum(RegionSchema);
