import { Flex, Heading, Separator, Text } from "@radix-ui/themes";
import { Suspense, useCallback, useReducer } from "react";
import { useParams } from "react-router";
import { useSchemaInfo } from "state/user/hooks";
import { clear } from "suspend-react";
import ErrorBoundary from "ui/errors/ErrorBoundary";
import { auditLogCache, AuditLogPanel, schemaCache, SchemaPanel } from "ui/schema/SchemaViewer";

export default function SchemaPage() {
  const { schemaId: schemaIdStr } = useParams<{ schemaId: string }>();
  const schemaId = BigInt(schemaIdStr ?? "0");
  const [_reloadCount, triggerRender] = useReducer((x: number) => x + 1, 0);

  const onRollbackSuccess = useCallback(() => {
    clear(schemaCache(schemaId));
    clear(auditLogCache(schemaId));
    triggerRender();
  }, [schemaId]);

  // This is the schema info from Whoami - we might want to load more detail in a separate call
  const schema = useSchemaInfo(schemaId);
  if (!schemaId || !schema) {
    return <div>Schema {schemaId.toString()} not found</div>;
  }

  return (
    <Flex gap="4" direction="column" mt="4">
      <title>Stately Cloud Console - Schema</title>
      <Heading size="7" trim="end">
        Schema Details
      </Heading>

      <Flex gap="4" direction="column">
        <Heading size="4" trim="end">
          Schema ID: {schemaId.toString()}
        </Heading>
        <ErrorBoundary name="Schema">
          <Suspense fallback={<Text>Loading schema...</Text>}>
            <SchemaPanel schemaId={schemaId} />
          </Suspense>
        </ErrorBoundary>
      </Flex>
      <Separator size="4" />

      <Flex gap="4" direction="column">
        <Heading size="4" trim="end">
          Audit Log
        </Heading>
        <ErrorBoundary name="AuditLog">
          <Suspense fallback={<Text>Loading audit log...</Text>}>
            <AuditLogPanel schemaId={schemaId} onRollbackSuccess={onRollbackSuccess} />
          </Suspense>
        </ErrorBoundary>
      </Flex>
    </Flex>
  );
}
