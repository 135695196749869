// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/create_access_key.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/create_access_key.proto.
 */
export const file_user_create_access_key =
  /*@__PURE__*/
  fileDesc(
    "Chx1c2VyL2NyZWF0ZV9hY2Nlc3Nfa2V5LnByb3RvEgxzdGF0ZWx5LnVzZXIiPwoWQ3JlYXRlQWNjZXNzS2V5UmVxdWVzdBIXCg9vcmdhbml6YXRpb25faWQYASABKAQSDAoEbmFtZRgCIAEoCSJEChdDcmVhdGVBY2Nlc3NLZXlSZXNwb25zZRIVCg1hY2Nlc3Nfa2V5X2lkGAEgASgEEhIKCmFjY2Vzc19rZXkYAiABKAlCeQoQY29tLnN0YXRlbHkudXNlckIUQ3JlYXRlQWNjZXNzS2V5UHJvdG9QAaICA1NVWKoCDFN0YXRlbHkuVXNlcsoCDFN0YXRlbHlcVXNlcuICGFN0YXRlbHlcVXNlclxHUEJNZXRhZGF0YeoCDVN0YXRlbHk6OlVzZXJiBnByb3RvMw",
  );

/**
 * Describes the message stately.user.CreateAccessKeyRequest.
 * Use `create(CreateAccessKeyRequestSchema)` to create a new message.
 */
export const CreateAccessKeyRequestSchema =
  /*@__PURE__*/
  messageDesc(file_user_create_access_key, 0);

/**
 * Describes the message stately.user.CreateAccessKeyResponse.
 * Use `create(CreateAccessKeyResponseSchema)` to create a new message.
 */
export const CreateAccessKeyResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_create_access_key, 1);
