import { Flex } from "@radix-ui/themes";
import { useParams } from "react-router";
import { useOrganizationInfo } from "state/user/hooks";
import { OrganizationSection } from "./OrganizationSection";

export default function OrgPage() {
  const { orgId: orgIdStr } = useParams<{ orgId: string }>();
  const orgId = BigInt(orgIdStr ?? "0");

  // This is the org info from Whoami - we might want to load more detail in a separate call
  const org = useOrganizationInfo(orgId);
  if (!orgId || !org) {
    return <div>Organization {orgId.toString()} not found</div>;
  }

  return (
    <Flex gap="4" direction="column" mt="4">
      <title>Stately Cloud Console - Organization</title>
      <OrganizationSection org={org} key={org.organizationId.toString()} />
    </Flex>
  );
}
