import "@redux-devtools/extension";
import { enableMapSet } from "immer";
import { Region } from "services/api/dbmanagement/region_pb.js";
import { SchemaID, StoreID } from "services/data/data";
import { createStore, deleteStore, ManagementClient, updateStoreName } from "services/management";
import {
  updateOrgName,
  UserClient,
  type OrganizationID,
  type ProjectID,
  type WhoamiResponse,
} from "services/user";
import { loadUser } from "services/user/load-user";
import { convertToError } from "utils/errors";
import { dedupePromise } from "utils/promises";
import { reportException } from "utils/sentry";
import { create } from "zustand";
import { combine, devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { Organization, Project, Schema, Store, User } from "./types";

/** This is the actual stored state, without the functions. */
interface State {
  whoamiError?: Error;
  whoamiLoaded: boolean;
  user?: User;
  projects: Map<ProjectID, Project>;
  organizations: Map<OrganizationID, Organization>;
  stores: Map<StoreID, Store>;
  schemas: Map<SchemaID, Schema>;
}

/** This is the state as it starts out on page load. */
const initialState: State = {
  user: undefined,
  projects: new Map(),
  organizations: new Map(),
  stores: new Map(),
  schemas: new Map(),
  whoamiLoaded: false,
};

// Tell Immer we're gonna be updating Maps and Sets.
enableMapSet();

/**
 * The User store includes information about the user and their resources (orgs,
 * projects, stores, etc).
 *
 * This shouldn't be used directly - it should be consumed via selector hooks
 * from ./selectors.ts.
 */
export const useUserStore = create(
  devtools(
    immer(
      combine(initialState, (set, get) => ({
        loadUser: dedupePromise(async (userClient: UserClient) => {
          if (get().whoamiLoaded) {
            return;
          }
          try {
            const whoami = await loadUser(userClient);
            set(
              { ...normalizeWhoami(whoami), whoamiError: undefined, whoamiLoaded: true },
              undefined,
              // This extra info is just so it shows up nicely in Redux DevTools
              {
                type: "whoamiLoaded",
                whoami,
              },
            );
          } catch (err) {
            reportException("whoami", err);
            set({ whoamiError: convertToError(err), whoamiLoaded: true }, undefined, {
              type: "whoamiError",
              err,
            });
          }
        }),

        createStore: async (
          managementClient: ManagementClient,
          region: Region,
          projectId: ProjectID,
          name: string,
          description: string,
        ) => {
          const result = await createStore(managementClient, region, projectId, name, description);
          const store: Store = {
            storeId: result.storeId,
            name,
            description,
            schemaId: 0n,
            projectId,
            defaultRegion: region,
          };
          set(
            (state) => {
              state.stores.set(store.storeId, store);
            },
            undefined,
            {
              type: "createStore",
              result,
            },
          );
          return store.storeId;
        },

        deleteStore: async (
          managementClient: ManagementClient,
          region: Region,
          storeId: StoreID,
        ) => {
          await deleteStore(managementClient, region, storeId);
          set(
            (state) => {
              state.stores.delete(storeId);
            },
            undefined,
            {
              type: "deleteStore",
              storeId,
            },
          );
        },

        /** Save a new name for an org and update it in the local state. */
        updateOrgName: async (userClient: UserClient, orgId: OrganizationID, name: string) => {
          await updateOrgName(userClient, orgId, name);
          set(
            (state) => {
              const org = state.organizations.get(orgId);
              if (org) {
                org.name = name;
              }
            },
            undefined,
            {
              type: "updateOrgName",
              orgId,
              name,
            },
          );
        },

        /** Save a new name for an org and update it in the local state. */
        updateStoreName: async (
          managementClient: ManagementClient,
          storeId: StoreID,
          name: string,
        ) => {
          const store = get().stores.get(storeId);
          if (!store) {
            throw new Error(`Store ${storeId} not found`);
          }
          await updateStoreName(managementClient, store.defaultRegion, storeId, name);
          set(
            (state) => {
              const store = state.stores.get(storeId);
              if (store) {
                store.name = name;
              }
            },
            undefined,
            {
              type: "updateStoreName",
              storeId,
              name,
            },
          );
        },
      })),
    ),
    // Configure Redux DevTools integration
    {
      serialize: {
        // It can't handle bigints
        replacer: (_key, value) => (typeof value === "bigint" ? value.toString() : value),
      },
      enabled: $featureFlags.reduxDevTools,
    },
  ),
);

/**
 * We save a normalized version of the whoami response to make it easier to have
 * a single source of truth per item, modify results immutably and build
 * selectors.
 */
function normalizeWhoami(
  whoami: WhoamiResponse | undefined,
): Pick<State, "user" | "projects" | "organizations" | "stores" | "schemas"> {
  const allOrganizations = new Map<OrganizationID, Organization>();
  const allProjects = new Map<ProjectID, Project>();
  const allStores = new Map<StoreID, Store>();
  const allSchemas = new Map<SchemaID, Schema>();
  let user: User | undefined;

  if (whoami) {
    const { organizations, ...userData } = whoami ?? {};
    user = userData;
    for (const { organization, projects } of organizations) {
      if (organization) {
        allOrganizations.set(organization.organizationId, organization);
        for (const { project, stores, schemas } of projects) {
          if (project) {
            allProjects.set(project.projectId, project);
            for (const { store } of stores) {
              if (store) {
                allStores.set(store.storeId, { ...store, projectId: project.projectId });
              }
            }
            for (const schema of schemas) {
              if (schema) {
                allSchemas.set(schema.schemaId, { ...schema, projectId: project.projectId });
              }
            }
          }
        }
      }
    }
  }

  return {
    organizations: allOrganizations,
    projects: allProjects,
    stores: allStores,
    schemas: allSchemas,
    user,
  };
}
