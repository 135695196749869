export type AlignLabel = "left" | "right" | "top" | "bottom";

/**
 * alignLabelToDirection converts an align label configuration to the corresponding
 * Flex box direction.
 * This is used for aligning labels to the left, right, top, or bottom of an input box.
 */
export const alignLabelToDirection: Record<
  AlignLabel,
  "row" | "row-reverse" | "column" | "column-reverse"
> = {
  left: "row",
  right: "row-reverse",
  top: "column",
  bottom: "column-reverse",
};
