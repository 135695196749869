// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/restore_store.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file dbmanagement/restore_store.proto.
 */
export const file_dbmanagement_restore_store =
  /*@__PURE__*/
  fileDesc(
    "CiBkYm1hbmFnZW1lbnQvcmVzdG9yZV9zdG9yZS5wcm90bxIUc3RhdGVseS5kYm1hbmFnZW1lbnQiZgoTUmVzdG9yZVN0b3JlUmVxdWVzdBIQCghzdG9yZV9pZBgBIAEoBBI9CgtwaXRfcmVzdG9yZRgCIAEoCzIoLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlBvaW50SW5UaW1lUmVzdG9yZSJeChJQb2ludEluVGltZVJlc3RvcmUSIgoYcmVzdG9yZV90aW1lX3VuaXhfbWlsbGlzGAIgASgESAASFAoKdXNlX2xhdGVzdBgDIAEoCEgAQg4KDHJlc3RvcmVfdGltZSIWChRSZXN0b3JlU3RvcmVSZXNwb25zZSIpChVEZXNjcmliZUJhY2t1cFJlcXVlc3QSEAoIc3RvcmVfaWQYASABKAQiVgoWRGVzY3JpYmVCYWNrdXBSZXNwb25zZRI8CgtiYWNrdXBfaW5mbxgBIAEoCzInLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlBvaW50SW5UaW1lQmFja3VwInwKEVBvaW50SW5UaW1lQmFja3VwEhkKEWJhY2t1cHNfYXZhaWxhYmxlGAEgASgIEiYKHmVhcmxpZXN0X2F2YWlsYWJsZV91bml4X21pbGxpcxgCIAEoBBIkChxsYXRlc3RfYXZhaWxhYmxlX3VuaXhfbWlsbGlzGAMgASgEQp4BChhjb20uc3RhdGVseS5kYm1hbmFnZW1lbnRCEVJlc3RvcmVTdG9yZVByb3RvUAGiAgNTRFiqAhRTdGF0ZWx5LkRibWFuYWdlbWVudMoCFFN0YXRlbHlcRGJtYW5hZ2VtZW504gIgU3RhdGVseVxEYm1hbmFnZW1lbnRcR1BCTWV0YWRhdGHqAhVTdGF0ZWx5OjpEYm1hbmFnZW1lbnRiBnByb3RvMw",
  );

/**
 * Describes the message stately.dbmanagement.RestoreStoreRequest.
 * Use `create(RestoreStoreRequestSchema)` to create a new message.
 */
export const RestoreStoreRequestSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_restore_store, 0);

/**
 * Describes the message stately.dbmanagement.PointInTimeRestore.
 * Use `create(PointInTimeRestoreSchema)` to create a new message.
 */
export const PointInTimeRestoreSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_restore_store, 1);

/**
 * Describes the message stately.dbmanagement.RestoreStoreResponse.
 * Use `create(RestoreStoreResponseSchema)` to create a new message.
 */
export const RestoreStoreResponseSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_restore_store, 2);

/**
 * Describes the message stately.dbmanagement.DescribeBackupRequest.
 * Use `create(DescribeBackupRequestSchema)` to create a new message.
 */
export const DescribeBackupRequestSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_restore_store, 3);

/**
 * Describes the message stately.dbmanagement.DescribeBackupResponse.
 * Use `create(DescribeBackupResponseSchema)` to create a new message.
 */
export const DescribeBackupResponseSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_restore_store, 4);

/**
 * Describes the message stately.dbmanagement.PointInTimeBackup.
 * Use `create(PointInTimeBackupSchema)` to create a new message.
 */
export const PointInTimeBackupSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_restore_store, 5);
