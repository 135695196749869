// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/config_group.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file dbmanagement/config_group.proto.
 */
export const file_dbmanagement_config_group =
  /*@__PURE__*/
  fileDesc(
    "Ch9kYm1hbmFnZW1lbnQvY29uZmlnX2dyb3VwLnByb3RvEhRzdGF0ZWx5LmRibWFuYWdlbWVudCKiAQoLR3JvdXBDb25maWcSEgoKZ3JvdXBfdHlwZRgBIAEoCRJGChRncm91cF92ZXJzaW9uX2NvbmZpZxgCIAEoCzIoLnN0YXRlbHkuZGJtYW5hZ2VtZW50Lkdyb3VwVmVyc2lvbkNvbmZpZxI3CgxzeW5jX2ZlYXR1cmUYAyABKAsyIS5zdGF0ZWx5LmRibWFuYWdlbWVudC5TeW5jRmVhdHVyZSIlChJHcm91cFZlcnNpb25Db25maWcSDwoHZW5hYmxlZBgBIAEoCCIxCgtTeW5jRmVhdHVyZRIiChp0b21ic3RvbmVfZHVyYXRpb25fc2Vjb25kcxgBIAEoBEKdAQoYY29tLnN0YXRlbHkuZGJtYW5hZ2VtZW50QhBDb25maWdHcm91cFByb3RvUAGiAgNTRFiqAhRTdGF0ZWx5LkRibWFuYWdlbWVudMoCFFN0YXRlbHlcRGJtYW5hZ2VtZW504gIgU3RhdGVseVxEYm1hbmFnZW1lbnRcR1BCTWV0YWRhdGHqAhVTdGF0ZWx5OjpEYm1hbmFnZW1lbnRiBnByb3RvMw",
  );

/**
 * Describes the message stately.dbmanagement.GroupConfig.
 * Use `create(GroupConfigSchema)` to create a new message.
 */
export const GroupConfigSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_config_group, 0);

/**
 * Describes the message stately.dbmanagement.GroupVersionConfig.
 * Use `create(GroupVersionConfigSchema)` to create a new message.
 */
export const GroupVersionConfigSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_config_group, 1);

/**
 * Describes the message stately.dbmanagement.SyncFeature.
 * Use `create(SyncFeatureSchema)` to create a new message.
 */
export const SyncFeatureSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_config_group, 2);
