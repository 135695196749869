// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/config_store.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file dbmanagement/config_store.proto.
 */
export const file_dbmanagement_config_store =
  /*@__PURE__*/
  fileDesc(
    "Ch9kYm1hbmFnZW1lbnQvY29uZmlnX3N0b3JlLnByb3RvEhRzdGF0ZWx5LmRibWFuYWdlbWVudCI1CgxCYWNrdXBDb25maWcSJQodcG9pbnRfaW5fdGltZV9iYWNrdXBzX2VuYWJsZWQYASABKAgiPAoTUmVxdWVzdFJhdGVMaW1pdGluZxISCgp3cml0ZV9yYXRlGAEgASgNEhEKCXJlYWRfcmF0ZRgCIAEoDSIpChNDdXN0b21UYWJsZUFjY2Vzc29yEhIKCnRhYmxlX25hbWUYASABKAlCnQEKGGNvbS5zdGF0ZWx5LmRibWFuYWdlbWVudEIQQ29uZmlnU3RvcmVQcm90b1ABogIDU0RYqgIUU3RhdGVseS5EYm1hbmFnZW1lbnTKAhRTdGF0ZWx5XERibWFuYWdlbWVudOICIFN0YXRlbHlcRGJtYW5hZ2VtZW50XEdQQk1ldGFkYXRh6gIVU3RhdGVseTo6RGJtYW5hZ2VtZW50YgZwcm90bzM",
  );

/**
 * Describes the message stately.dbmanagement.BackupConfig.
 * Use `create(BackupConfigSchema)` to create a new message.
 */
export const BackupConfigSchema = /*@__PURE__*/ messageDesc(file_dbmanagement_config_store, 0);

/**
 * Describes the message stately.dbmanagement.RequestRateLimiting.
 * Use `create(RequestRateLimitingSchema)` to create a new message.
 */
export const RequestRateLimitingSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_config_store, 1);

/**
 * Describes the message stately.dbmanagement.CustomTableAccessor.
 * Use `create(CustomTableAccessorSchema)` to create a new message.
 */
export const CustomTableAccessorSchema =
  /*@__PURE__*/
  messageDesc(file_dbmanagement_config_store, 2);
