// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/get.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_schemaservice_schema } from "./schema_pb.js";

/**
 * Describes the file schemaservice/get.proto.
 */
export const file_schemaservice_get =
  /*@__PURE__*/
  fileDesc(
    "ChdzY2hlbWFzZXJ2aWNlL2dldC5wcm90bxIVc3RhdGVseS5zY2hlbWFzZXJ2aWNlIkAKCkdldFJlcXVlc3QSEQoJc2NoZW1hX2lkGAMgASgEEhkKEXNjaGVtYV92ZXJzaW9uX2lkGAIgASgNSgQIARACIkEKC0dldFJlc3BvbnNlEjIKBnNjaGVtYRgBIAEoCzIiLnN0YXRlbHkuc2NoZW1hc2VydmljZS5TY2hlbWFNb2RlbEKaAQoZY29tLnN0YXRlbHkuc2NoZW1hc2VydmljZUIIR2V0UHJvdG9QAaICA1NTWKoCFVN0YXRlbHkuU2NoZW1hc2VydmljZcoCFVN0YXRlbHlcU2NoZW1hc2VydmljZeICIVN0YXRlbHlcU2NoZW1hc2VydmljZVxHUEJNZXRhZGF0YeoCFlN0YXRlbHk6OlNjaGVtYXNlcnZpY2ViBnByb3RvMw",
    [file_schemaservice_schema],
  );

/**
 * Describes the message stately.schemaservice.GetRequest.
 * Use `create(GetRequestSchema)` to create a new message.
 */
export const GetRequestSchema = /*@__PURE__*/ messageDesc(file_schemaservice_get, 0);

/**
 * Describes the message stately.schemaservice.GetResponse.
 * Use `create(GetResponseSchema)` to create a new message.
 */
export const GetResponseSchema = /*@__PURE__*/ messageDesc(file_schemaservice_get, 1);
