// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file dbmanagement/service.proto (package stately.dbmanagement, syntax proto3)
/* eslint-disable */

import { fileDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_dbmanagement_create_store } from "./create_store_pb.js";
import { file_dbmanagement_delete_store } from "./delete_store_pb.js";
import { file_dbmanagement_describe_store } from "./describe_store_pb.js";
import { file_dbmanagement_restore_store } from "./restore_store_pb.js";
import { file_dbmanagement_update_store } from "./update_store_pb.js";

/**
 * Describes the file dbmanagement/service.proto.
 */
export const file_dbmanagement_service =
  /*@__PURE__*/
  fileDesc(
    "ChpkYm1hbmFnZW1lbnQvc2VydmljZS5wcm90bxIUc3RhdGVseS5kYm1hbmFnZW1lbnQyjwUKEU1hbmFnZW1lbnRTZXJ2aWNlEmQKC0NyZWF0ZVN0b3JlEiguc3RhdGVseS5kYm1hbmFnZW1lbnQuQ3JlYXRlU3RvcmVSZXF1ZXN0Gikuc3RhdGVseS5kYm1hbmFnZW1lbnQuQ3JlYXRlU3RvcmVSZXNwb25zZSIAEm0KDURlc2NyaWJlU3RvcmUSKi5zdGF0ZWx5LmRibWFuYWdlbWVudC5EZXNjcmliZVN0b3JlUmVxdWVzdBorLnN0YXRlbHkuZGJtYW5hZ2VtZW50LkRlc2NyaWJlU3RvcmVSZXNwb25zZSIDkAIBEmQKC1VwZGF0ZVN0b3JlEiguc3RhdGVseS5kYm1hbmFnZW1lbnQuVXBkYXRlU3RvcmVSZXF1ZXN0Gikuc3RhdGVseS5kYm1hbmFnZW1lbnQuVXBkYXRlU3RvcmVSZXNwb25zZSIAEmcKC0RlbGV0ZVN0b3JlEiguc3RhdGVseS5kYm1hbmFnZW1lbnQuRGVsZXRlU3RvcmVSZXF1ZXN0Gikuc3RhdGVseS5kYm1hbmFnZW1lbnQuRGVsZXRlU3RvcmVSZXNwb25zZSIDkAICEm0KDkRlc2NyaWJlQmFja3VwEisuc3RhdGVseS5kYm1hbmFnZW1lbnQuRGVzY3JpYmVCYWNrdXBSZXF1ZXN0Giwuc3RhdGVseS5kYm1hbmFnZW1lbnQuRGVzY3JpYmVCYWNrdXBSZXNwb25zZSIAEmcKDFJlc3RvcmVTdG9yZRIpLnN0YXRlbHkuZGJtYW5hZ2VtZW50LlJlc3RvcmVTdG9yZVJlcXVlc3QaKi5zdGF0ZWx5LmRibWFuYWdlbWVudC5SZXN0b3JlU3RvcmVSZXNwb25zZSIAQpkBChhjb20uc3RhdGVseS5kYm1hbmFnZW1lbnRCDFNlcnZpY2VQcm90b1ABogIDU0RYqgIUU3RhdGVseS5EYm1hbmFnZW1lbnTKAhRTdGF0ZWx5XERibWFuYWdlbWVudOICIFN0YXRlbHlcRGJtYW5hZ2VtZW50XEdQQk1ldGFkYXRh6gIVU3RhdGVseTo6RGJtYW5hZ2VtZW50YgZwcm90bzM",
    [
      file_dbmanagement_create_store,
      file_dbmanagement_delete_store,
      file_dbmanagement_describe_store,
      file_dbmanagement_restore_store,
      file_dbmanagement_update_store,
    ],
  );

/**
 * The DB Management service is used to read info about, provision, and delete
 * Stores, which are our top level container for data. In general, customers
 * will not interact with this service, as they get a default store created for
 * them per project. However, it is useful for clients to discover Store IDs,
 * and for us to be able to create and destroy stores for testing.
 *
 * @generated from service stately.dbmanagement.ManagementService
 */
export const ManagementService = /*@__PURE__*/ serviceDesc(file_dbmanagement_service, 0);
