// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/remove_users_from_organization.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/remove_users_from_organization.proto.
 */
export const file_user_remove_users_from_organization =
  /*@__PURE__*/
  fileDesc(
    "Cil1c2VyL3JlbW92ZV91c2Vyc19mcm9tX29yZ2FuaXphdGlvbi5wcm90bxIMc3RhdGVseS51c2VyIk8KIlJlbW92ZVVzZXJzRnJvbU9yZ2FuaXphdGlvblJlcXVlc3QSFwoPb3JnYW5pemF0aW9uX2lkGAEgASgEEhAKCHVzZXJfaWRzGAIgAygEIiUKI1JlbW92ZVVzZXJzRnJvbU9yZ2FuaXphdGlvblJlc3BvbnNlQoUBChBjb20uc3RhdGVseS51c2VyQiBSZW1vdmVVc2Vyc0Zyb21Pcmdhbml6YXRpb25Qcm90b1ABogIDU1VYqgIMU3RhdGVseS5Vc2VyygIMU3RhdGVseVxVc2Vy4gIYU3RhdGVseVxVc2VyXEdQQk1ldGFkYXRh6gINU3RhdGVseTo6VXNlcmIGcHJvdG8z",
  );

/**
 * Describes the message stately.user.RemoveUsersFromOrganizationRequest.
 * Use `create(RemoveUsersFromOrganizationRequestSchema)` to create a new message.
 */
export const RemoveUsersFromOrganizationRequestSchema =
  /*@__PURE__*/
  messageDesc(file_user_remove_users_from_organization, 0);

/**
 * Describes the message stately.user.RemoveUsersFromOrganizationResponse.
 * Use `create(RemoveUsersFromOrganizationResponseSchema)` to create a new message.
 */
export const RemoveUsersFromOrganizationResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_remove_users_from_organization, 1);
