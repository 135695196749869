// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/service.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_schemaservice_bind } from "./bind_pb.js";
import { file_schemaservice_create } from "./create_pb.js";
import { file_schemaservice_get } from "./get_pb.js";
import { file_schemaservice_list_audit_log } from "./list_audit_log_pb.js";
import { file_schemaservice_put } from "./put_pb.js";
import { file_schemaservice_update } from "./update_pb.js";
import { file_schemaservice_validate } from "./validate_pb.js";

/**
 * Describes the file schemaservice/service.proto.
 */
export const file_schemaservice_service =
  /*@__PURE__*/
  fileDesc(
    "ChtzY2hlbWFzZXJ2aWNlL3NlcnZpY2UucHJvdG8SFXN0YXRlbHkuc2NoZW1hc2VydmljZTKNBQoNU2NoZW1hU2VydmljZRJOCgNQdXQSIS5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuUHV0UmVxdWVzdBoiLnN0YXRlbHkuc2NoZW1hc2VydmljZS5QdXRSZXNwb25zZSIAEmAKCFZhbGlkYXRlEiYuc3RhdGVseS5zY2hlbWFzZXJ2aWNlLlZhbGlkYXRlUmVxdWVzdBonLnN0YXRlbHkuc2NoZW1hc2VydmljZS5WYWxpZGF0ZVJlc3BvbnNlIgOQAgESUQoDR2V0EiEuc3RhdGVseS5zY2hlbWFzZXJ2aWNlLkdldFJlcXVlc3QaIi5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuR2V0UmVzcG9uc2UiA5ACARJXCgZDcmVhdGUSJC5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuQ3JlYXRlUmVxdWVzdBolLnN0YXRlbHkuc2NoZW1hc2VydmljZS5DcmVhdGVSZXNwb25zZSIAElQKBEJpbmQSIi5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuQmluZFJlcXVlc3QaIy5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuQmluZFJlc3BvbnNlIgOQAgISbAoMTGlzdEF1ZGl0TG9nEiouc3RhdGVseS5zY2hlbWFzZXJ2aWNlLkxpc3RBdWRpdExvZ1JlcXVlc3QaKy5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuTGlzdEF1ZGl0TG9nUmVzcG9uc2UiA5ACARJaCgZVcGRhdGUSJC5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuVXBkYXRlUmVxdWVzdBolLnN0YXRlbHkuc2NoZW1hc2VydmljZS5VcGRhdGVSZXNwb25zZSIDkAICQp4BChljb20uc3RhdGVseS5zY2hlbWFzZXJ2aWNlQgxTZXJ2aWNlUHJvdG9QAaICA1NTWKoCFVN0YXRlbHkuU2NoZW1hc2VydmljZcoCFVN0YXRlbHlcU2NoZW1hc2VydmljZeICIVN0YXRlbHlcU2NoZW1hc2VydmljZVxHUEJNZXRhZGF0YeoCFlN0YXRlbHk6OlNjaGVtYXNlcnZpY2ViBnByb3RvMw",
    [
      file_schemaservice_bind,
      file_schemaservice_create,
      file_schemaservice_get,
      file_schemaservice_list_audit_log,
      file_schemaservice_put,
      file_schemaservice_update,
      file_schemaservice_validate,
    ],
  );

/**
 * Schema is the service for creating, updating, and retrieving a Schema for a
 * StatelyDB Schema. A single StatelyDB Schema will contain versions. Each
 * version defines the shape of items. Stores can be bound to one and only
 * one schema but can work with any version of that schema. Schema management
 * is performed using the Stately CLI.
 *
 * @generated from service stately.schemaservice.SchemaService
 */
export const SchemaService = /*@__PURE__*/ serviceDesc(file_schemaservice_service, 0);
