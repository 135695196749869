// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/bind.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file schemaservice/bind.proto.
 */
export const file_schemaservice_bind =
  /*@__PURE__*/
  fileDesc(
    "ChhzY2hlbWFzZXJ2aWNlL2JpbmQucHJvdG8SFXN0YXRlbHkuc2NoZW1hc2VydmljZSJBCgtCaW5kUmVxdWVzdBIQCghzdG9yZV9pZBgBIAEoBBIRCglzY2hlbWFfaWQYAiABKAQSDQoFZm9yY2UYAyABKAgiDgoMQmluZFJlc3BvbnNlQpsBChljb20uc3RhdGVseS5zY2hlbWFzZXJ2aWNlQglCaW5kUHJvdG9QAaICA1NTWKoCFVN0YXRlbHkuU2NoZW1hc2VydmljZcoCFVN0YXRlbHlcU2NoZW1hc2VydmljZeICIVN0YXRlbHlcU2NoZW1hc2VydmljZVxHUEJNZXRhZGF0YeoCFlN0YXRlbHk6OlNjaGVtYXNlcnZpY2ViBnByb3RvMw",
  );

/**
 * Describes the message stately.schemaservice.BindRequest.
 * Use `create(BindRequestSchema)` to create a new message.
 */
export const BindRequestSchema = /*@__PURE__*/ messageDesc(file_schemaservice_bind, 0);

/**
 * Describes the message stately.schemaservice.BindResponse.
 * Use `create(BindResponseSchema)` to create a new message.
 */
export const BindResponseSchema = /*@__PURE__*/ messageDesc(file_schemaservice_bind, 1);
