import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Flex, Heading } from "@radix-ui/themes";
import { Suspense, useState } from "react";
import { useParams } from "react-router";
import { AccessKeyOverview } from "services/api/user/list_access_keys_pb";
import { getAccessKey, listAccessKeys, OrganizationID } from "services/user";
import { useUserClient } from "services/user/useUserClient";
import { useOrganizationInfo } from "state/user/hooks";
import { suspend } from "suspend-react";
import CopyText from "ui/utils/CopyText";
import { AddAccessKeyButton, OnAccessKeyAdded } from "./AddAccessKey";
import { DeleteAccessKeyButton, OnAccessKeyDeleted } from "./DeleteAccessKey";
import styles from "./ManageAccessKeysPage.m.css";

export default function ManageAccessKeysPage() {
  const { orgId: orgIdStr } = useParams<{ orgId: string }>();
  const orgId = BigInt(orgIdStr ?? "0");

  // This is the org info from Whoami - we might want to load more detail in a separate call
  const org = useOrganizationInfo(orgId);
  if (!orgId || !org) {
    return <div>Organization {orgId.toString()} not found</div>;
  }

  return (
    <Flex gap="4" direction="column" mt="4">
      <title>Stately Cloud Console - Manage Access Keys</title>
      <Heading size="7">Manage Access Keys for organization "{org.name}"</Heading>
      <Suspense fallback={<div>Loading keys...</div>}>
        <KeysList orgId={orgId} />
      </Suspense>
    </Flex>
  );
}

function KeysList({ orgId }: { orgId: OrganizationID }) {
  const userClient = useUserClient();
  const remoteKeys = suspend(async () => listAccessKeys(userClient, orgId), ["keys", orgId]);
  const [keys, setKeys] = useState(remoteKeys);

  const handleAccessKeyAdded: OnAccessKeyAdded = (key) => {
    setKeys((keys) => [...keys, key]);
  };
  const handleAccessKeyDeleted: OnAccessKeyDeleted = (keyId) => {
    setKeys((keys) => keys.filter((k) => k.accessKeyId !== keyId));
  };

  return (
    <>
      {keys ? (
        keys.map((key) => (
          <AccessKeyInfo
            key={key.accessKeyId.toString()}
            ak={key}
            onAccessKeyDeleted={handleAccessKeyDeleted}
          />
        ))
      ) : (
        <div>No access keys exist</div>
      )}
      <Flex gap="2" direction="row" justify="end">
        <AddAccessKeyButton orgId={orgId} onAccessKeyAdded={handleAccessKeyAdded} />
      </Flex>
    </>
  );
}

export function AccessKeyInfo({
  ak,
  onAccessKeyDeleted,
}: {
  ak: AccessKeyOverview;
  onAccessKeyDeleted?: OnAccessKeyDeleted;
}) {
  const userClient = useUserClient();
  const [accessKey, setAccessKey] = useState<string>();
  const loadAccessKey = async () => {
    const accessKeyStr = await getAccessKey(userClient, ak.accessKeyId);
    setAccessKey(accessKeyStr);
  };

  return (
    <Card>
      <Flex direction="row" gap="4" align="center">
        <Heading size="4" className={styles.title}>
          <FontAwesomeIcon icon={faKey} />
          {ak.name.toString()}
        </Heading>
        <Flex direction="row" gap="4" align="center" flexGrow="1" ml="4">
          {accessKey ? (
            <CopyText text={accessKey} />
          ) : (
            <Button variant="ghost" onClick={loadAccessKey}>
              Show access key
            </Button>
          )}
        </Flex>
        {onAccessKeyDeleted && (
          <DeleteAccessKeyButton ak={ak} onAccessKeyDeleted={onAccessKeyDeleted} />
        )}
      </Flex>
    </Card>
  );
}
