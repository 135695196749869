import { faKey, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Flex, Heading } from "@radix-ui/themes";
import { useUserClient } from "services/user/useUserClient";
import { useProjectsForOrg, useUpdateOrgName } from "state/user/hooks";
import { Organization } from "state/user/types";
import { ProjectSection } from "ui/project/ProjectSection";
import Link from "ui/shared/Link";
import { CreateStoreButton } from "ui/store/CreateStoreButton";
import EditableText from "ui/utils/EditableText";
import styles from "./OrganizationSection.m.css";

export function OrganizationSection({ org }: { org: Organization }) {
  const updateOrgName = useUpdateOrgName();
  const projects = useProjectsForOrg(org.organizationId);
  const userClient = useUserClient();

  const handleEditName = async (name: string) => {
    await updateOrgName(userClient, org.organizationId, name);
  };

  return (
    <Flex direction="column" gap="4">
      <Heading weight="light" size="5" className={styles.title}>
        <FontAwesomeIcon icon={faUserGroup} aria-hidden="true" />
        Organization:
        <EditableText
          text={org.name}
          onEdit={handleEditName}
          className={styles.text}
          label="Edit Name"
          failureMessage="We weren't able to update this organization's name"
          failureTitle="Failed to update organization name"
        />
      </Heading>
      {projects.map((project) => (
        <ProjectSection key={project.projectId.toString()} project={project} />
      ))}
      <Flex gap="2" direction="row" justify="end">
        <Link to={`/organization/${org.organizationId}/keys`}>
          <Button variant="soft">
            <FontAwesomeIcon icon={faKey} aria-hidden="true" /> Manage Access Keys
          </Button>
        </Link>
        <CreateStoreButton orgId={org.organizationId} />
        {/* <Button variant="soft">
          <FontAwesomeIcon icon={faShapes} aria-hidden="true" /> Create Schema
        </Button> */}
      </Flex>
    </Flex>
  );
}
