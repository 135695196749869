import { useEffect } from "react";
import { useLocation } from "react-router";
import { gaPageView } from "services/analytics/ga";

/**
 * Record page views to Google Analytics.
 */
export default function GATracker() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Replace IDs so we can consolidate paths
    gaPageView(pathname.replaceAll(/\/\d+\//g, "/id/"));
  }, [pathname]);

  return null;
}
