import { create } from "@bufbuild/protobuf";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps, Dialog, Text } from "@radix-ui/themes";
import { noop } from "es-toolkit";
import { useCallback, useState } from "react";
import {
  AccessKeyOverview,
  AccessKeyOverviewSchema,
} from "services/api/user/list_access_keys_pb.js";
import { createAccessKey, OrganizationID } from "services/user";
import { useUserClient } from "services/user/useUserClient";
import DialogButtons from "ui/shared/DialogButtons";
import TextInput from "ui/shared/TextInput";
import CopyText from "ui/utils/CopyText";
import { useSubmitFormHandler } from "ui/utils/forms";
export type OnAccessKeyAdded = (key: AccessKeyOverview) => void;

export function AddAccessKeyButton({
  orgId,
  onAccessKeyAdded,
  buttonProps,
}: {
  orgId: OrganizationID;
  onAccessKeyAdded?: OnAccessKeyAdded;
  buttonProps?: ButtonProps;
}) {
  const [showDialog, setShowDialog] = useState(false);
  const close = useCallback(() => setShowDialog(false), []);

  const handleOnAccessKeyAdded = (key: AccessKeyOverview) => {
    onAccessKeyAdded?.(key);
    close();
  };

  return (
    <>
      <Dialog.Root open={showDialog} onOpenChange={setShowDialog}>
        <Dialog.Trigger>
          <Button variant="soft" {...buttonProps}>
            <FontAwesomeIcon icon={faKey} aria-hidden="true" />
            <Text>Create Access Key</Text>
          </Button>
        </Dialog.Trigger>
        <Dialog.Content width="80vw" maxWidth="80vw">
          <AddAccessKeyForm orgId={orgId} onAccessKeyAdded={handleOnAccessKeyAdded} />
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
}

function AddAccessKeyForm({
  orgId,
  onAccessKeyAdded,
}: {
  orgId: OrganizationID;
  onAccessKeyAdded: OnAccessKeyAdded;
}) {
  const client = useUserClient();
  const [name, setName] = useState<string>("");
  const [accessKey, setAccessKey] = useState<string>("");
  const [accessKeyOverview, setAccessKeyOverview] = useState<AccessKeyOverview>();

  // TODO: select user from a combobox of all users instead of requiring ID

  const [loading, handleSubmit, error] = useSubmitFormHandler(async () => {
    const { accessKey, accessKeyId } = await createAccessKey(client, orgId, name);
    setAccessKey(accessKey);
    setAccessKeyOverview(
      create(AccessKeyOverviewSchema, { accessKeyId, name, createdAtMs: BigInt(Date.now()) }),
    );
  }, noop);

  const onClose = () => {
    onAccessKeyAdded(accessKeyOverview!);
  };

  const canSubmit = name.length > 0 && !loading;

  return (
    <form onSubmit={handleSubmit}>
      {accessKey ? (
        <>
          <Dialog.Title>Access Key Added</Dialog.Title>
          <CopyText text={accessKey} />
          <Text>
            Keep this access key safe and secret - it's like a password that allows full access to
            your store!
          </Text>
          <DialogButtons>
            <Button type="button" variant="solid" onClick={onClose}>
              Close
            </Button>
          </DialogButtons>
        </>
      ) : (
        <>
          <Dialog.Title>Add an access key to this organization</Dialog.Title>
          <TextInput label="Name" onChange={setName} value={name} required />
          <DialogButtons progress={loading && "Adding access key..."} error={error}>
            <Dialog.Close>
              <Button variant="soft" color="gray">
                Cancel
              </Button>
            </Dialog.Close>
            <Button type="submit" variant="solid" disabled={!canSubmit}>
              Add Access Key
            </Button>
          </DialogButtons>
        </>
      )}
    </form>
  );
}
