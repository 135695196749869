// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file user/list_access_keys.proto (package stately.user, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file user/list_access_keys.proto.
 */
export const file_user_list_access_keys =
  /*@__PURE__*/
  fileDesc(
    "Cht1c2VyL2xpc3RfYWNjZXNzX2tleXMucHJvdG8SDHN0YXRlbHkudXNlciIwChVMaXN0QWNjZXNzS2V5c1JlcXVlc3QSFwoPb3JnYW5pemF0aW9uX2lkGAEgASgEIk4KFkxpc3RBY2Nlc3NLZXlzUmVzcG9uc2USNAoLYWNjZXNzX2tleXMYASADKAsyHy5zdGF0ZWx5LnVzZXIuQWNjZXNzS2V5T3ZlcnZpZXciTwoRQWNjZXNzS2V5T3ZlcnZpZXcSFQoNYWNjZXNzX2tleV9pZBgBIAEoBBIMCgRuYW1lGAIgASgJEhUKDWNyZWF0ZWRfYXRfbXMYAyABKARCeAoQY29tLnN0YXRlbHkudXNlckITTGlzdEFjY2Vzc0tleXNQcm90b1ABogIDU1VYqgIMU3RhdGVseS5Vc2VyygIMU3RhdGVseVxVc2Vy4gIYU3RhdGVseVxVc2VyXEdQQk1ldGFkYXRh6gINU3RhdGVseTo6VXNlcmIGcHJvdG8z",
  );

/**
 * Describes the message stately.user.ListAccessKeysRequest.
 * Use `create(ListAccessKeysRequestSchema)` to create a new message.
 */
export const ListAccessKeysRequestSchema = /*@__PURE__*/ messageDesc(file_user_list_access_keys, 0);

/**
 * Describes the message stately.user.ListAccessKeysResponse.
 * Use `create(ListAccessKeysResponseSchema)` to create a new message.
 */
export const ListAccessKeysResponseSchema =
  /*@__PURE__*/
  messageDesc(file_user_list_access_keys, 1);

/**
 * Describes the message stately.user.AccessKeyOverview.
 * Use `create(AccessKeyOverviewSchema)` to create a new message.
 */
export const AccessKeyOverviewSchema = /*@__PURE__*/ messageDesc(file_user_list_access_keys, 2);
