import { Box, Flex } from "@radix-ui/themes";
import { Dispatch, SetStateAction, useId, useMemo } from "react";
import { AlignLabel, alignLabelToDirection } from "ui/shared/alignLabel";
import { textChangeHandler } from "ui/utils/forms";
import { errorMessage } from "utils/errors";
import styles from "./TextInput.m.css";

export default function TextInput({
  label,
  alignLabel = "left",
  onChange,
  inputRef,
  validation,
  ...props
}: Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> & {
  alignLabel?: AlignLabel;
  label: string;
  validation?: (v: string) => void;
  onChange: Dispatch<SetStateAction<string>>;
  inputRef?: React.Ref<HTMLInputElement>;
}) {
  const error = useMemo(() => {
    if (validation && props.value) {
      try {
        validation(props.value as string);
      } catch (e) {
        return errorMessage(e);
      }
    }
  }, [validation, props.value]);
  const id = useId();

  return (
    <Flex direction="column">
      <Flex direction={alignLabelToDirection[alignLabel]} display="inline-flex" gap="2">
        {Boolean(label) && (
          <Box className={styles.label}>
            <label htmlFor={id}>{label}</label>
          </Box>
        )}
        <Box flexGrow="1">
          <input
            id={id}
            name={id}
            ref={inputRef}
            type="text"
            {...props}
            onChange={textChangeHandler(onChange)}
          />
        </Box>
      </Flex>
      {error && (
        <Box>
          <span className={styles.error}>{error}</span>
        </Box>
      )}
    </Flex>
  );
}
