import { Box, Flex, Heading, Inset, Link, Strong, Text } from "@radix-ui/themes";
import styles from "./WelcomeCard.m.css";

export default function WelcomeCard() {
  return (
    <Box>
      <Inset clip="padding-box" side="top" pb="current" className={styles.welcomeImage} />
      <Flex gap="4" direction="column" mt="4">
        <Heading>Welcome Aboard!</Heading>
        <Text as="p" size="3">
          <Strong>You're almost ready to start using StatelyDB.</Strong> The next step is to create
          a store and set up a schema. We'd also love if you'd drop us an email{" "}
          <Link href="mailto:support@stately.cloud">support@stately.cloud</Link> and tell us a
          little bit about your use case. If you're just checking us out and exploring, that's
          totally fine!
          <Link href="https://docs.stately.cloud">Read our documentation</Link> for a complete
          getting started guide.
        </Text>
      </Flex>
    </Box>
  );
}
