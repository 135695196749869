// @generated by protoc-gen-es v2.2.3 with parameter "target=js+dts,import_extension=.js"
// @generated from file schemaservice/update.proto (package stately.schemaservice, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_dbmanagement_update_store } from "../dbmanagement/update_store_pb.js";
import { file_schemaservice_schema_info } from "./schema_info_pb.js";

/**
 * Describes the file schemaservice/update.proto.
 */
export const file_schemaservice_update =
  /*@__PURE__*/
  fileDesc(
    "ChpzY2hlbWFzZXJ2aWNlL3VwZGF0ZS5wcm90bxIVc3RhdGVseS5zY2hlbWFzZXJ2aWNlIlgKDVVwZGF0ZVJlcXVlc3QSEQoJc2NoZW1hX2lkGAEgASgEEjQKB3VwZGF0ZXMYAiADKAsyIy5zdGF0ZWx5LnNjaGVtYXNlcnZpY2UuU2NoZW1hVXBkYXRlIkMKDlVwZGF0ZVJlc3BvbnNlEjEKBnNjaGVtYRgBIAEoCzIhLnN0YXRlbHkuc2NoZW1hc2VydmljZS5TY2hlbWFJbmZvIpgBCgxTY2hlbWFVcGRhdGUSNwoLdXBkYXRlX25hbWUYASABKAsyIC5zdGF0ZWx5LmRibWFuYWdlbWVudC5VcGRhdGVOYW1lSAASRQoSdXBkYXRlX2Rlc2NyaXB0aW9uGAIgASgLMicuc3RhdGVseS5kYm1hbmFnZW1lbnQuVXBkYXRlRGVzY3JpcHRpb25IAEIICgZ1cGRhdGVCnQEKGWNvbS5zdGF0ZWx5LnNjaGVtYXNlcnZpY2VCC1VwZGF0ZVByb3RvUAGiAgNTU1iqAhVTdGF0ZWx5LlNjaGVtYXNlcnZpY2XKAhVTdGF0ZWx5XFNjaGVtYXNlcnZpY2XiAiFTdGF0ZWx5XFNjaGVtYXNlcnZpY2VcR1BCTWV0YWRhdGHqAhZTdGF0ZWx5OjpTY2hlbWFzZXJ2aWNlYgZwcm90bzM",
    [file_dbmanagement_update_store, file_schemaservice_schema_info],
  );

/**
 * Describes the message stately.schemaservice.UpdateRequest.
 * Use `create(UpdateRequestSchema)` to create a new message.
 */
export const UpdateRequestSchema = /*@__PURE__*/ messageDesc(file_schemaservice_update, 0);

/**
 * Describes the message stately.schemaservice.UpdateResponse.
 * Use `create(UpdateResponseSchema)` to create a new message.
 */
export const UpdateResponseSchema = /*@__PURE__*/ messageDesc(file_schemaservice_update, 1);

/**
 * Describes the message stately.schemaservice.SchemaUpdate.
 * Use `create(SchemaUpdateSchema)` to create a new message.
 */
export const SchemaUpdateSchema = /*@__PURE__*/ messageDesc(file_schemaservice_update, 2);
